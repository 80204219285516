//summary-card.js
import React, { useEffect, useState } from 'react'

import GridWrapper from '../../grid-wrapper'
import SummaryDonutChart from '../../marketing/components/summary-donut-chart'

const SummaryCard = ({ salesRdx }) => {
	const [topValue, setTopValue] = useState(' ')
	const [bottomValue, setBottomValue] = useState(' ')

	useEffect(() => {
		setBottomValue(
			salesRdx.sales_last30Days?.length > 0
				? salesRdx.sales_last30Days.length
				: ' ',
		)
		setTopValue(
			salesRdx.sales_last7Days?.length > 0
				? salesRdx.sales_last7Days.length
				: ' ',
		)
	}, [salesRdx.sales_last30Days, salesRdx.sales_last7Days])

	return (
		<GridWrapper
			firstLine={'Appts Set'}
			secondLine={'TEXT'}
			colSpan={' md:col-span-1 '}
		>
			{/* <!-- Large Text --> */}
			<div className='flex justify-between'>
				<p className='relative z-10 mt-6 text-xl'>{`Previous 7 days: `}</p>
				<p className='relative z-10 mt-6 text-xl'>{topValue}</p>
			</div>
			{/* <!-- Small Text --> */}
			<div className='flex justify-between'>
				<p className='mt-6 opacity-50 line-clamp-6'>Previous 30 days:</p>
				<p className='mt-6 opacity-50 line-clamp-6 tex'>{bottomValue}</p>
			</div>

			<SummaryDonutChart
				data={[
					{ name: 'Last 7 days', value: topValue },
					{ name: 'Last 30 days', value: bottomValue },
				]}
			/>
		</GridWrapper>
	)
}

export default SummaryCard
