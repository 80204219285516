//kpi-charts.js
import React, { useEffect, useState } from 'react'
import KPIBarChart from './kpi-chart-bar'
import KPILineChart from './kpi-chart-line'
import { ICONS } from '../../../constants/icons'

function calculateDataByRep(data) {
	// Objects to hold the processed data, keyed by sales rep.
	const contractsDataByRep = {}
	const appointmentsDataByRep = {}

	data.forEach((event) => {
		const { salesRep, eventType } = event

		// Initialize the record for this salesRep if it doesn't already exist
		if (!contractsDataByRep[salesRep]) {
			contractsDataByRep[salesRep] = 0
		}
		if (!appointmentsDataByRep[salesRep]) {
			appointmentsDataByRep[salesRep] = 0
		}

		// Increment the relevant counter based on the event type
		if (['contractSigned', 'solarSold'].includes(eventType)) {
			contractsDataByRep[salesRep]++
		} else if (eventType === 'initialAppointmentCompleted') {
			appointmentsDataByRep[salesRep]++
		}
	})

	// Convert the data into arrays suitable for charting
	const contractsSignedData = Object.keys(contractsDataByRep)
		.map((salesRep) => ({
			salesRep,
			contractsSigned: contractsDataByRep[salesRep],
		}))
		.sort((a, b) => b.contractsSigned - a.contractsSigned) // Sort by contractsSigned descending

	const appointmentsRanData = Object.keys(appointmentsDataByRep)
		.map((salesRep) => ({
			salesRep,
			appointmentsRan: appointmentsDataByRep[salesRep],
		}))
		.sort((a, b) => b.appointmentsRan - a.appointmentsRan) // Sort by appointmentsRan descending

	return { appointmentsRanData, contractsSignedData }
}
const processBarChartData = (events) => {
	const dataByRep = calculateDataByRep(events)
	const updatedKpiBarCharts = [
		{
			kpi: `APPT'S RAN`,
			icon: ICONS.data.calendarCheck,
			dataKey: 'appointmentsRan',
			xDataKey: 'salesRep',
			data: dataByRep.appointmentsRanData,
		},
		{
			kpi: 'CONTRACTS SIGNED',
			icon: ICONS.construction.contract,
			dataKey: 'contractsSigned',
			xDataKey: 'salesRep',
			data: dataByRep.contractsSignedData,
		},
	]
	return updatedKpiBarCharts
}
const getDayAbbreviation = (date) => {
	const dayNames = ['S', 'M', 'T', 'W', 'TH', 'F', 'SA']
	return dayNames[date.getDay()]
}
const initializeChartData = (label) => {
	return ['S', 'M', 'T', 'W', 'TH', 'F', 'SA'].map((day) => ({
		day,
		[label]: 0,
	}))
}
const processLineChartData = (events) => {
	const contractsSignedData = initializeChartData('contractSigned')

	const appointmentsRanData = initializeChartData('appointmentsRan')
	events.forEach((event) => {
		const eventDate = new Date(event.timeStamp)
		const dayAbbrev = getDayAbbreviation(eventDate)
		const dayIndex = contractsSignedData.findIndex(
			(day) => day.day === dayAbbrev,
		)

		if (['contractSigned', 'solarSold'].includes(event.eventType)) {
			contractsSignedData[dayIndex].contractSigned++
		} else if (event.eventType === 'initialAppointmentCompleted') {
			appointmentsRanData[dayIndex].appointmentsRan++
		}
	})

	const updatedKpiLineCharts = [
		{
			kpi: `APPT'S RAN`,
			icon: ICONS.data.calendarCheck,
			dataKey: 'appointmentsRan',
			xDataKey: 'day',
			data: appointmentsRanData,
		},
		{
			kpi: 'CONTRACTS SIGNED',
			icon: ICONS.construction.contract,
			dataKey: 'contractSigned',
			xDataKey: 'day',
			data: contractsSignedData,
		},
	]
	return updatedKpiLineCharts
}

const KPICharts = ({ salesEvents, activeChartType }) => {
	const [chartData, setChartData] = useState({
		lineChartData: [],
		barChartData: [],
	})

	useEffect(() => {
		const lineChartData = processLineChartData(salesEvents)
		const barChartData = processBarChartData(salesEvents)

		setChartData({ lineChartData, barChartData })
	}, [salesEvents])

	return (
		<div className=' col-span-1 lg:col-span-6'>
			{activeChartType === 'line' &&
				chartData.lineChartData.map((e, idx) => (
					<KPILineChart
						dataKeyX={e.xDataKey}
						key={idx}
						kpi={e.kpi}
						icon={e.icon}
						dataKeyLine={e.dataKey}
						data={e.data}
					/>
				))}
			{activeChartType === 'bar' &&
				chartData.barChartData.map((e, idx) => (
					<KPIBarChart
						dataKeyX={e.xDataKey}
						key={idx}
						kpi={e.kpi}
						icon={e.icon}
						dataKeyBar={e.dataKey}
						data={e.data}
						tickFormatter={true}
					/>
				))}
		</div>
	)
}

export default KPICharts
