//summary-card.js
import React, { useEffect, useState } from 'react'
import {
	CartesianGrid,
	Line,
	ComposedChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
	Bar,
} from 'recharts'
import { CONST_COLORS } from '../../../../constants/general'

const SummaryCard = ({ leadsRdx }) => {
	const STRUCTURE = [
		{
			title: 'Leads Generated',
			value: 'Test',
			data: [
				{ day: 'Monday', count: 20 },
				{ day: 'Tuesday', count: 25 },
				{ day: 'Wednesday', count: 18 },
				{ day: 'Thursday', count: 30 },
				{ day: 'Friday', count: 22 },
			],
			xKey: 'day',
			lineKey: 'count',
			type: 'BAR',
		},
		{
			title: 'Appointments Set',
			value: 'Test',
			data: [
				{ day: 'Monday', count: 10 },
				{ day: 'Tuesday', count: 15 },
				{ day: 'Wednesday', count: 12 },
				{ day: 'Thursday', count: 18 },
				{ day: 'Friday', count: 20 },
			],
			xKey: 'day',
			lineKey: 'count',
			type: 'LINE',
		},
		{
			title: 'Conversion Rate',
			value: 'Test',
			data: [
				{ day: 'Monday', rate: 5 },
				{ day: 'Tuesday', rate: 7 },
				{ day: 'Wednesday', rate: 6 },
				{ day: 'Thursday', rate: 8 },
				{ day: 'Friday', rate: 7 },
			],
			xKey: 'day',
			lineKey: 'rate',
			type: 'LINE',
		},
	]

	return (
		<div
			className={`rounded-xl bg-white dark:bg-romanDark2 flex flex-col  h-full min-h-full justify-evenly space-y-4`}
		>
			{STRUCTURE.map((badge, idx) => {
				const { data, xKey, lineKey, type } = badge
				return (
					<div key={`${idx}-${badge.title}`} className='flex-1 h-full'>
						<div className='rounded-t-xl h-1/6 text-white font-bold text-md w-full bg-romanGoldScorecard items-center text-center'>
							{badge.title}
						</div>
						<div className='rounded-b-xl h-5/6 w-full bg-romanGray200Scorecard items-center text-center'>
							<ResponsiveContainer width='100%' height='100%'>
								<ComposedChart
									data={data}
									margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
								>
									<CartesianGrid strokeDasharray='1 1' />

									<Tooltip />
									{type === 'LINE' && (
										<Line
											type='monotone'
											dataKey={lineKey}
											stroke={CONST_COLORS.romanRedScorecard}
											fill={CONST_COLORS.romanRedScorecard}
											activeDot={{ r: 8 }}
										/>
									)}
									{type === 'BAR' && (
										<Bar
											type='monotone'
											dataKey={lineKey}
											stroke={CONST_COLORS.romanRedScorecard}
											fill={CONST_COLORS.romanRedScorecard}
											activeDot={{ r: 8 }}
										/>
									)}
								</ComposedChart>
							</ResponsiveContainer>
						</div>
					</div>
				)
			})}
		</div>
	)
}

export default SummaryCard
