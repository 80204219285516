import axios from 'axios'
import { CONST_API } from '../../../constants/api'
import { REDUX_CONST } from '../../constants/'
import { CONST_ERRORS } from '../../../constants/general'
import { logout } from '../userActions'

export const getEvents =
	({ queryObject }) =>
	async (dispatch, getState) => {
		// console.log('Events:Request:GetEvents')
		try {
			dispatch({
				type: REDUX_CONST.giddyUp.eventConstants.getEvents.request,
			})

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
				},
				params: queryObject,
			}

			const { data } = await axios.get(
				CONST_API.channels.giddyUp + CONST_API.routes.giddyUp.events.getEvents,
				config,
			)
			// console.log('Events:Response:GetEvents')
			// console.log(data)

			dispatch({
				type: REDUX_CONST.giddyUp.eventConstants.getEvents.success,
				payload: data,
			})
		} catch (error) {
			console.log(error)
			const message =
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			if (message === CONST_ERRORS['401ExpiredToken']) {
				dispatch(logout())
			}
			dispatch({
				type: REDUX_CONST.giddyUp.eventConstants.getEvents.fail,
				payload: message,
			})
		}
	}

export const getLeads =
	({ timeRange, eventTypes }) =>
	async (dispatch, getState) => {
		// console.log('Events:Request:GetEvents')
		try {
			dispatch({
				type: REDUX_CONST.giddyUp.eventConstants.getLeads.request,
			})

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
				},
				params: { timeRange, eventTypes },
			}

			const { data } = await axios.get(
				CONST_API.channels.giddyUp + CONST_API.routes.giddyUp.events.getLeads,
				config,
			)
			// console.log('Events:Response:GetEvents')
			// console.log(data)

			dispatch({
				type: REDUX_CONST.giddyUp.eventConstants.getLeads.success,
				payload: data,
			})
		} catch (error) {
			console.log(error)
			const message =
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			if (message === CONST_ERRORS['401ExpiredToken']) {
				dispatch(logout())
			}
			dispatch({
				type: REDUX_CONST.giddyUp.eventConstants.getLeads.fail,
				payload: message,
			})
		}
	}
export const getSales =
	({ timeRange, eventTypes, phaseChanges }) =>
	async (dispatch, getState) => {
		// console.log('Events:Request:GetEvents')
		try {
			dispatch({
				type: REDUX_CONST.giddyUp.eventConstants.getSales.request,
			})

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
				},
				params: { timeRange, eventTypes, phaseChanges },
			}

			const { data } = await axios.get(
				CONST_API.channels.giddyUp + CONST_API.routes.giddyUp.events.getSales,
				config,
			)
			// console.log('Events:Response:GetEvents')
			// console.log(data)

			dispatch({
				type: REDUX_CONST.giddyUp.eventConstants.getSales.success,
				payload: data,
			})
		} catch (error) {
			console.log(error)

			const message =
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			if (message === CONST_ERRORS['401ExpiredToken']) {
				dispatch(logout())
			}
			dispatch({
				type: REDUX_CONST.giddyUp.eventConstants.getSales.fail,
				payload: message,
			})
		}
	}
export const getScorecardData =
	({ key, eventTypes, phaseChanges, weekOffset }) =>
	async (dispatch, getState) => {
		// console.log('Events:Request:GetEvents')
		try {
			dispatch({
				type: REDUX_CONST.giddyUp.eventConstants.getScorecardData.request,
			})

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
				},
				params: { key, eventTypes, phaseChanges, weekOffset: weekOffset || 0 },
			}

			const { data } = await axios.get(
				CONST_API.channels.giddyUp +
					CONST_API.routes.giddyUp.events.getScorecardData,
				config,
			)
			// console.log('Events:Response:GetEvents')
			// console.log(data)

			dispatch({
				type: REDUX_CONST.giddyUp.eventConstants.getScorecardData.success,
				payload: data,
			})
		} catch (error) {
			console.log(error)
			const message =
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			if (message === CONST_ERRORS['401ExpiredToken']) {
				dispatch(logout())
			}
			dispatch({
				type: REDUX_CONST.giddyUp.eventConstants.getScorecardData.fail,
				payload: message,
			})
		}
	}
export const getLeaderboardData =
	({ key, eventTypes, phaseChanges }) =>
	async (dispatch, getState) => {
		// console.log('Events:Request:GetEvents')
		try {
			dispatch({
				type: REDUX_CONST.giddyUp.eventConstants.getLeaderboardData.request,
			})

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
				},
				params: { key, eventTypes, phaseChanges },
			}

			const { data } = await axios.get(
				CONST_API.channels.giddyUp +
					CONST_API.routes.giddyUp.events.getLeaderboardData,
				config,
			)
			// console.log('Events:Response:GetEvents')
			// console.log(data)

			dispatch({
				type: REDUX_CONST.giddyUp.eventConstants.getLeaderboardData.success,
				payload: data,
			})
		} catch (error) {
			console.log(error)
			const message =
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			if (message === CONST_ERRORS['401ExpiredToken']) {
				dispatch(logout())
			}
			dispatch({
				type: REDUX_CONST.giddyUp.eventConstants.getLeaderboardData.fail,
				payload: message,
			})
		}
	}
