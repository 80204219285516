//ytd-monthly.js
import React, { useEffect, useState } from 'react'

const DATA = [
	{ metric: 'Revenue', value: 77867034.58 },
	{ metric: 'COGS', value: 61559899.6 },
	{ metric: 'Gross Profit', value: 16307134.98 },
	{ metric: 'EBITDA', value: 5604229.63 },
]

const COMPONENT_STRUCTURE = [
	{
		col1: 'Metric',
		col2: 'Jan',
		col3: 'Feb',
		col4: 'Mar',
		col5: 'Apr',
		col6: 'May',
		col7: 'Jun',
		col8: 'Jul',
		col9: 'Aug',
		col10: 'Sept',
		col11: 'Oct',
		col12: 'Nov',
		col13: 'Dec',
		col14: 'Total',
		header: true,
	}, //header
	{ col1: 'Revenue' },
	{ col1: 'COGS' },
	{ col1: 'Gross Profit' },
	{ col1: 'EBITDA' },
]

const formatDollar = ({ value, format, factor, decimals }) => {
	// Possible Formats: ['DOLLAR','NUMBER'] // if DOLLAR use $ prefix
	// Possible Factors: [1, 1000, 1000000]  // divide the value by the factor
	// Possible Decimals: [0,1,2]  // use this as the decimals displayed
	// Values = floating point decimal positive and negative

	// Check if value is a number and if factor is not zero to avoid division by zero
	if (typeof value !== 'number' || factor === 0) return ''

	// Divide the value by the factor and fix to the specified decimal places
	let formattedValue = (value / factor).toFixed(decimals)

	// Add commas as thousand separators
	formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

	// Add dollar sign if format is 'DOLLAR'
	if (format === 'DOLLAR') {
		formattedValue = `$${formattedValue}`
	}

	return formattedValue
}

const YTDMonthly = ({ dollarBaseFactor, dollarDecimal, dollarFormat }) => {
	const [components, setComponents] = useState(COMPONENT_STRUCTURE)

	// MAP THE DATA INTO THE STRUCTURE WHENEVER OPTIONS OR DATA CHANGES
	useEffect(() => {
		const updatedComponents = COMPONENT_STRUCTURE.map((component) => {
			if (component.header) {
				return component // Return as is for headers
			}

			const dataEntry = DATA.find((d) => d.metric === component.col1)

			return {
				...component,
				col2: dataEntry
					? formatDollar({
							value: dataEntry.value,
							format: dollarFormat,
							factor: dollarBaseFactor,
							decimals: dollarDecimal,
					  })
					: null,
				col3: dataEntry
					? formatDollar({
							value: dataEntry.value,
							format: dollarFormat,
							factor: dollarBaseFactor,
							decimals: dollarDecimal,
					  })
					: null,
				col4: dataEntry
					? formatDollar({
							value: dataEntry.value,
							format: dollarFormat,
							factor: dollarBaseFactor,
							decimals: dollarDecimal,
					  })
					: null,
				col5: dataEntry
					? formatDollar({
							value: dataEntry.value,
							format: dollarFormat,
							factor: dollarBaseFactor,
							decimals: dollarDecimal,
					  })
					: null,
				col6: dataEntry
					? formatDollar({
							value: dataEntry.value,
							format: dollarFormat,
							factor: dollarBaseFactor,
							decimals: dollarDecimal,
					  })
					: null,
				col7: dataEntry
					? formatDollar({
							value: dataEntry.value,
							format: dollarFormat,
							factor: dollarBaseFactor,
							decimals: dollarDecimal,
					  })
					: null,
				col8: dataEntry
					? formatDollar({
							value: dataEntry.value,
							format: dollarFormat,
							factor: dollarBaseFactor,
							decimals: dollarDecimal,
					  })
					: null,
				col9: dataEntry
					? formatDollar({
							value: dataEntry.value,
							format: dollarFormat,
							factor: dollarBaseFactor,
							decimals: dollarDecimal,
					  })
					: null,
				col10: dataEntry
					? formatDollar({
							value: dataEntry.value,
							format: dollarFormat,
							factor: dollarBaseFactor,
							decimals: dollarDecimal,
					  })
					: null,
				col11: dataEntry
					? formatDollar({
							value: dataEntry.value,
							format: dollarFormat,
							factor: dollarBaseFactor,
							decimals: dollarDecimal,
					  })
					: null,
				col12: dataEntry
					? formatDollar({
							value: dataEntry.value,
							format: dollarFormat,
							factor: dollarBaseFactor,
							decimals: dollarDecimal,
					  })
					: null,
				col13: dataEntry
					? formatDollar({
							value: dataEntry.value,
							format: dollarFormat,
							factor: dollarBaseFactor,
							decimals: dollarDecimal,
					  })
					: null,
				col14: dataEntry
					? formatDollar({
							value: dataEntry.value,
							format: dollarFormat,
							factor: dollarBaseFactor,
							decimals: dollarDecimal,
					  })
					: null,
			}
		})

		setComponents(updatedComponents)
	}, [DATA, dollarBaseFactor, dollarDecimal, dollarFormat])

	return (
		<div className='my-2 overflow-scroll'>
			{components.map((component, idx) => (
				<div
					key={idx}
					className={`${
						idx === 0
							? 'font-bold border-b-2 border-romanLightTextActive dark:border-romanDarkTextActive  '
							: ' '
					} grid grid-cols-15 space-x-4 w-1000`}
				>
					<div className='col-span-2 text-romanLightTextActive dark:text-romanDarkTextActive font-bold '>
						{component.col1}
					</div>
					<div className='col-span-1 inline-block text-romanLightTextActive dark:text-romanDarkTextActive'>
						{component.col2}
					</div>
					<div className='col-span-1 inline-block text-romanLightTextActive dark:text-romanDarkTextActive'>
						{component.col3}
					</div>
					<div className='col-span-1 text-romanLightTextActive dark:text-romanDarkTextActive'>
						{component.col4}
					</div>
					<div className='col-span-1 text-romanLightTextActive dark:text-romanDarkTextActive'>
						{component.col5}
					</div>
					<div className='col-span-1 text-romanLightTextActive dark:text-romanDarkTextActive'>
						{component.col6}
					</div>
					<div className='col-span-1 text-romanLightTextActive dark:text-romanDarkTextActive'>
						{component.col7}
					</div>
					<div className='col-span-1 text-romanLightTextActive dark:text-romanDarkTextActive'>
						{component.col8}
					</div>
					<div className='col-span-1 text-romanLightTextActive dark:text-romanDarkTextActive'>
						{component.col9}
					</div>
					<div className='col-span-1 text-romanLightTextActive dark:text-romanDarkTextActive'>
						{component.col10}
					</div>
					<div className='col-span-1 text-romanLightTextActive dark:text-romanDarkTextActive'>
						{component.col11}
					</div>
					<div className='col-span-1 text-romanLightTextActive dark:text-romanDarkTextActive'>
						{component.col12}
					</div>
					<div className='col-span-1 text-romanLightTextActive dark:text-romanDarkTextActive'>
						{component.col13}
					</div>
					<div className='col-span-1 text-romanLightTextActive dark:text-romanDarkTextActive'>
						{component.col14}
					</div>
				</div>
			))}
		</div>
	)
}

export default YTDMonthly
