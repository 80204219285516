//kpi-chart-line.js
import React from 'react'
import {
	CartesianGrid,
	Line,
	LineChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts'
import { CONST_COLORS } from '../../../constants/general'

const KPILineChart = ({
	data,
	kpi,
	icon,
	dataKeyLine,
	dataKeyLineB,
	dataKeyX,
	lineType,
	height,
	fillA,
	strokeA,
	strokeDasharrayA,
	strokeWidthA,
	fillB,
	strokeB,
	strokeDasharrayB,
	strokeWidthB,
}) => {
	const maxValue = Math.max(...data.map((item) => item[dataKeyLine])) + 2
	return (
		<div className='grid grid-cols-1 md:grid-cols-5'>
			<div className='col-span-1 md:col-span-1 text-center text-white bg-romanGray800Scorecard rounded-lg mt-8 z-10'>
				<div className='flex flex-col text-center  justify-center items-center  space-y-6 p-5'>
					<div className=' text-xl font-bold'>{kpi}</div>

					<div className='text-6xl text-romanGoldScorecard'>{icon}</div>
				</div>
			</div>
			<div className='col-span-1 md:col-span-4 text-black text-center bg-romanGray200Scorecard rounded-b-lg md:rounded-r-lg -mt-2 md:-ml-2  md:mt-8 z-8 pt-6 md:pt-0 pl-4'>
				{/* Recharts Line Chart */}
				<ResponsiveContainer width='100%' height={height || 200}>
					<LineChart
						data={data}
						margin={{ top: 20, right: 20, left: -20, bottom: 5 }}
					>
						<CartesianGrid strokeDasharray='3 3' />
						<XAxis dataKey={dataKeyX} tick={{ fontSize: 12 }} />
						<YAxis tick={{ fontSize: 12 }} domain={[0, maxValue]} />
						<Tooltip />
						<Line
							type={lineType || 'monotone'}
							dataKey={dataKeyLine}
							fill={fillA || CONST_COLORS.romanRedScorecard}
							stroke={strokeA || CONST_COLORS.romanRedScorecard}
							strokeDasharray={strokeDasharrayA || undefined}
							strokeWidth={strokeWidthA || 1}
							activeDot={{ r: 5 }}
						/>
						{dataKeyLineB && (
							<Line
								type={lineType || 'monotone'}
								dataKey={dataKeyLineB}
								strokeDasharray={strokeDasharrayB}
								strokeWidth={strokeWidthB}
								fill={fillB || CONST_COLORS.romanRedScorecard}
								stroke={strokeB || CONST_COLORS.romanRedScorecard}
								activeDot={{ r: 5 }}
							/>
						)}
					</LineChart>
				</ResponsiveContainer>
			</div>
		</div>
	)
}

export default KPILineChart
