export const GET_ESTIMATES_REQUEST = 'GET_ESTIMATES_REQUEST'
export const GET_ESTIMATES_SUCCESS = 'GET_ESTIMATES_SUCCESS'
export const GET_ESTIMATES_FAIL = 'GET_ESTIMATES_FAIL'

export const estimateConstants = {
	getEstimates: {
		request: GET_ESTIMATES_REQUEST,
		success: GET_ESTIMATES_SUCCESS,
		fail: GET_ESTIMATES_FAIL,
	},
}
