import React from 'react'
import {
	ComposedChart,
	Bar,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from 'recharts'
import { CONST_COLORS } from '../../../../constants/general'
const CustomLegend = (props) => {
	const { payload } = props

	return (
		<div className='flex flex-col space-y-3 text-xs'>
			<div className='flex space-x-3 items-center justify-center'>
				{/* <div className='text-lg font-semibold'>{ICONS_CHARTS.bar2}</div> */}
				<div className='flex space-x-2'>
					{payload.map((entry, index) => (
						<div
							className='font-bold px-2'
							key={`item-${index}`}
							style={{ color: entry.color }}
						>
							{entry.value === 'leads'
								? 'Leads'
								: entry.value === 'appointments'
								? 'Appointments Set'
								: 'Appt Set Rate '}
						</div>
					))}
				</div>
			</div>
		</div>
	)
}
// Example dataset
const data = [
	{ date: '01/28/24', leads: 70, appointments: 35, rate: 60 },
	{ date: '02/04/24', leads: 73, appointments: 37, rate: 61 },
	{ date: '02/11/24', leads: 76, appointments: 39, rate: 62 },
	{ date: '02/18/24', leads: 79, appointments: 41, rate: 63 },
	{ date: '02/25/24', leads: 82, appointments: 44, rate: 64 },
	{ date: '03/03/24', leads: 85, appointments: 46, rate: 65 },
	{ date: '03/10/24', leads: 88, appointments: 49, rate: 66 },
	{ date: '03/17/24', leads: 91, appointments: 51, rate: 67 },
	{ date: '03/24/24', leads: 94, appointments: 54, rate: 68 },
	{ date: '03/31/24', leads: 97, appointments: 57, rate: 69 },
	{ date: '04/07/24', leads: 100, appointments: 60, rate: 70 },
	{ date: '04/14/24', leads: 103, appointments: 62, rate: 71 },
	{ date: '04/21/24', leads: 106, appointments: 65, rate: 72 },
	{ date: '04/28/24', leads: 109, appointments: 68, rate: 73 },
	{ date: '05/05/24', leads: 112, appointments: 71, rate: 74 },
	{ date: '05/12/24', leads: 115, appointments: 74, rate: 75 },
	{ date: '05/19/24', leads: 118, appointments: 77, rate: 76 },
	{ date: '05/26/24', leads: 121, appointments: 81, rate: 77 },
	{ date: '06/02/24', leads: 124, appointments: 84, rate: 78 },
	{ date: '06/09/24', leads: 127, appointments: 87, rate: 79 },
	{ date: '06/16/24', leads: 130, appointments: 91, rate: 80 },
	{ date: '06/23/24', leads: 133, appointments: 94, rate: 81 },
	{ date: '06/30/24', leads: 136, appointments: 97, rate: 82 },
	{ date: '07/07/24', leads: 139, appointments: 101, rate: 83 },
	{ date: '07/14/24', leads: 142, appointments: 105, rate: 84 },
	{ date: '07/21/24', leads: 145, appointments: 108, rate: 85 },
]

const CustomTooltip = ({ active, payload, label }) => {
	if (active) {
		return (
			<div className='custom-tooltip'>
				<p className='label'>{`${label}`}</p>
				<p className='intro'>{`Leads Generated: ${payload[0].value}`}</p>
				<p className='intro'>{`Appointments Set: ${payload[1].value}`}</p>
				<p className='intro'>{`Appt Set Rate: ${payload[2].value}%`}</p>
			</div>
		)
	}

	return null
}

const ISTAnalysisChart = () => (
	<ResponsiveContainer
		width='100%'
		height={400}
		className={'bg-romanGray200Scorecard m-4 rounded-xl'}
	>
		<ComposedChart
			data={data}
			margin={{
				top: 0,
				right: 0,
				left: 0,
				bottom: 0,
			}}
		>
			<CartesianGrid strokeDasharray='3 3' />
			<XAxis dataKey='date' tick={{ fontSize: 12 }} />
			<YAxis
				yAxisId='left'
				orientation='left'
				stroke={CONST_COLORS.romanDarkBorder1}
				tick={{ fontSize: 12 }}
			/>
			<YAxis
				yAxisId='right'
				orientation='right'
				stroke={CONST_COLORS.romanRedScorecard}
				tick={{ fontSize: 12 }}
			/>
			<Tooltip content={<CustomTooltip />} />
			<Legend content={<CustomLegend />} />
			<Bar
				yAxisId='left'
				dataKey='leads'
				fill={CONST_COLORS.romanGoldScorecard}
			/>
			<Line
				yAxisId='left'
				type={'monotone'}
				dataKey='appointments'
				stroke={CONST_COLORS.romanDarkBorder1}
				fill={CONST_COLORS.romanDarkBorder1}
			/>
			<Line
				yAxisId='right'
				type='monotone'
				dataKey='rate'
				stroke={CONST_COLORS.romanRedScorecard}
				fill={CONST_COLORS.romanRedScorecard}
			/>
		</ComposedChart>
	</ResponsiveContainer>
)

export default ISTAnalysisChart
