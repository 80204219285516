//scorecard-header.js
import React from 'react'

const ScorecardHeader = ({ title, component }) => {
	return (
		<div className='flex flex-col md:flex-row  justify-between w-full py-2 text-center font-bold text-3xl lg:text-justify md:text-4xl text-romanLightTextActive dark:text-romanDarkTextActive'>
			<div>{title}</div>
			{component}
		</div>
	)
}

export default ScorecardHeader
