import { REDUX_CONST } from '../../constants'

export const getEstimates = (state = { data: [] }, action) => {
	switch (action.type) {
		case REDUX_CONST.giddyUp.estimateConstants.getEstimates.request:
			return { loading: true }
		case REDUX_CONST.giddyUp.estimateConstants.getEstimates.success:
			return { data: action.payload }
		case REDUX_CONST.giddyUp.estimateConstants.getEstimates.fail:
			return { error: action.payload }
		default:
			return state
	}
}
