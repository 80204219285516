//kpi-menu.js
import React from 'react'
import { CONST_ADD, CONST_DELETE } from '../../../constants/general'
import { ICONS_DEFAULT } from '../../../constants/icons'

const KpiMenu = ({
	weekOffset,
	onSetWeekOffset,
	play,
	onSwitchIntervalHandler,
	switchInterval,
	onTogglePlayHandler,
	buttons,
}) => {
	return (
		<div className='flex flex-col md:flex-row  justify-between w-full py-2 text-center text-xl font-semibold items-center lg:text-justify md:text-3xl text-romanDarkTextInactive dark:text-romanDarkTextActive'>
			<div className='flex justify-between space-x-2 items-center'>
				<div
					onClick={() => onSetWeekOffset(CONST_DELETE)}
					className='hover:border-1 rounded-full p-1 items-center text-center justify-center'
				>
					{ICONS_DEFAULT.chevronLeft}
				</div>
				<div className=''>
					{weekOffset === 0
						? `Current Week's Metrics`
						: weekOffset === -1
						? `Last Week's Metrics`
						: `${-weekOffset} Weeks Ago`}
				</div>
				<div
					onClick={() => onSetWeekOffset(CONST_ADD)}
					className='hover:border-1 rounded-full p-1 items-center text-center justify-center'
				>
					{ICONS_DEFAULT.chevronRight}
				</div>
			</div>
			<div className='flex flex-col md:flex-row justify-center items-center  mt-4 md:mt-0 mb-4 md:mb-0 text-lg space-y-4 space-x-0 md:space-y-0 md:space-x-4'>
				<div className='flex space-x-4'>
					{play && (
						<div
							className={`group relative px-4 py-2 bg-romanGoldScorecard text-black rounded hover:bg-opacity-80`}
						>
							{/* <div className='absolute right-0 top-0 bottom-0 flex items-center pr-2 opacity-0 group-hover:opacity-100'> */}
							<button
								onClick={() => onSwitchIntervalHandler(CONST_ADD)}
								className='absolute -left-5 -top-5 text-center justify-center text-sm rounded-full border-1 p-1.5 text-black dark:text-white flex items-center pr-2 opacity-0 group-hover:opacity-100'
							>
								{ICONS_DEFAULT.plus}
							</button>
							<button
								onClick={() => onSwitchIntervalHandler(CONST_DELETE)}
								className='absolute -left-5 -bottom-5  text-center justify-center text-sm rounded-full border-1 p-1.5 text-black dark:text-white flex items-center pr-2 opacity-0 group-hover:opacity-100'
							>
								{ICONS_DEFAULT.minus}
							</button>
							{/* </div> */}
							{`${switchInterval}s`}
						</div>
					)}

					<button
						className={`px-4 py-3 ${
							play
								? 'bg-romanRedScorecard text-white '
								: 'bg-romanGoldScorecard text-black'
						}  rounded hover:bg-opacity-80`}
						onClick={onTogglePlayHandler}
					>
						{play ? ICONS_DEFAULT.pause : ICONS_DEFAULT.play}
					</button>
				</div>

				{buttons}
			</div>
		</div>
	)
}

export default KpiMenu
