//throughput-trend-chart.js
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import {
	ResponsiveContainer,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	Line,
	ComposedChart,
	ReferenceLine,
} from 'recharts'

import { CONST_ROMAN_KPI, CONST_DATES } from '../../../../constants/general'
import { ICONS_CHARTS } from '../../../../constants/icons'
const CustomLabel = ({ viewBox, value }) => {
	const { x, y } = viewBox
	return (
		<text x={x} y={y} dy={-4} fill='red' fontSize={10} textAnchor='start'>
			{`Sales Target = ${value}`}
		</text>
	)
}

const CustomLegend = (props) => {
	const { payload } = props

	const barItems = payload.filter((item) =>
		['Reroof', 'Repairs', 'Insurance'].includes(item.value),
	)
	const lineItems = payload.filter(
		(item) => !['Reroof', 'Repairs', 'Insurance'].includes(item.value),
	)

	return (
		<div className='flex flex-col space-y-3 text-xs'>
			<div className='flex space-x-3 items-center justify-center'>
				<div className='text-lg font-semibold'>{ICONS_CHARTS.bar2}</div>
				<div className='flex space-x-2'>
					{barItems.map((entry, index) => (
						<div key={`item-${index}`} style={{ color: entry.color }}>
							{entry.value}
						</div>
					))}
				</div>
			</div>
			<div className='flex space-x-3 items-center justify-center'>
				<div className='text-lg font-semibold'>{ICONS_CHARTS.line}</div>
				<div className='flex space-x-2'>
					{lineItems.map((entry, index) => (
						<div key={`item-${index}`} style={{ color: entry.color }}>
							{entry.value}
						</div>
					))}
				</div>
			</div>
		</div>
	)
}
const CustomTooltip = ({ active, payload }) => {
	if (active && payload && payload.length) {
		const payloadMapped = payload.map((entry) => {
			let newEntry = { ...entry }
			switch (entry.dataKey) {
				case 'Reroof':
				case 'Repairs':
				case 'Insurance':
					newEntry.chartType = 'Bar'
					break

				default:
					newEntry.chartType = 'Line'
					break
			}
			return newEntry
		})

		// Grouping the payload by chartType
		const groupedByType = {
			Bar: payloadMapped.filter((entry) => entry.chartType === 'Bar'),
			Line: payloadMapped.filter((entry) => entry.chartType === 'Line'),
		}

		return (
			<div className='outline-none p-2 bg-romanLight3 dark:bg-romanDark3 bg-opacity-80 dark:bg-opacity-50 text-xs'>
				{Object.entries(groupedByType).map(([type, items]) => (
					<div key={type}>
						<div className='font-bold underline'>{type} Chart:</div>
						<ul>
							{items.map((entry, index) => (
								<li
									className='pl-2'
									style={{ color: entry.color }}
									key={`item-${index}`}
								>
									{`${entry.name} : ${entry.value}`}
								</li>
							))}
						</ul>
					</div>
				))}
			</div>
		)
	}
	return null
}

const ThroughputTrendChart = ({ rawData, barChartColors, lineChartColors }) => {
	const [chartData, setChartData] = useState([])
	const [timePeriod, setTimePeriod] = useState(CONST_DATES.week)
	const [salesTarget, setSalesTarget] = useState(
		CONST_ROMAN_KPI.salesThroughput.target,
	)

	const groupDataByTimePeriod = (data, period) => {
		return data.reduce((acc, curr) => {
			let dateKey

			switch (period) {
				case CONST_DATES.day:
					dateKey = moment(curr.date).format('YYYY-MM-DD')
					break
				case CONST_DATES.week:
					dateKey = moment(curr.date).startOf('week').format('YYYY-MM-DD')
					break
				case CONST_DATES.month:
					dateKey = moment(curr.date).startOf('month').format('YYYY-MM-DD')
					break
				default:
					throw new Error('Invalid time period')
			}

			if (!acc[dateKey]) {
				acc[dateKey] = {
					chartDay: dateKey,
					'Leads Generated': 0,
					'Appointments Set': 0,
					'Appointments Ran': 0,
					'Inbound Lead': 0,
					Reroof: 0,
					Insurance: 0,
					Repairs: 0,
				}
			}

			const mappedEventType =
				CONST_ROMAN_KPI.salesThroughput[curr.eventType]?.chartName

			if (mappedEventType) {
				acc[dateKey][mappedEventType] = (acc[dateKey][mappedEventType] || 0) + 1
			}

			return acc
		}, {})
	}

	useEffect(() => {
		if (rawData && rawData.length > 0) {
			const groupedData = groupDataByTimePeriod(rawData, timePeriod)
			// console.log(groupedData)
			setChartData(Object.values(groupedData))

			switch (timePeriod) {
				case CONST_DATES.day:
					setSalesTarget(Math.ceil(CONST_ROMAN_KPI.salesThroughput.target / 7))

					break
				case CONST_DATES.week:
					setSalesTarget(CONST_ROMAN_KPI.salesThroughput.target)
					break
				case CONST_DATES.month:
					setSalesTarget(
						Math.ceil((CONST_ROMAN_KPI.salesThroughput.target * 52) / 12),
					)
					break

				default:
					break
			}
		} else {
			setChartData([])
			setSalesTarget(CONST_ROMAN_KPI.salesThroughput.target)
		}
	}, [rawData, timePeriod])

	return (
		<div style={{ width: '100%', height: 300 }} className='grow mt-10'>
			<div className='flex space-x-2 py-1 px-2 justify-end text-xs'>
				<button
					className={`rounded-full py-1 px-2 border-1 ${
						timePeriod === CONST_DATES.day
							? 'border-romanRed2  '
							: 'border-romanLightTextInactive'
					}  ${
						timePeriod === CONST_DATES.day
							? 'dark:border-romanGold1'
							: 'dark:border-romanDarkBorder1'
					}`}
					onClick={() => setTimePeriod(CONST_DATES.day)}
				>
					Day
				</button>
				<button
					className={`rounded-full py-1 px-2 border-1 ${
						timePeriod === CONST_DATES.week
							? 'border-romanRed2  '
							: 'border-romanLightTextInactive'
					}  ${
						timePeriod === CONST_DATES.week
							? 'dark:border-romanGold1'
							: 'dark:border-romanDarkBorder1'
					}`}
					onClick={() => setTimePeriod(CONST_DATES.week)}
				>
					Week
				</button>
				<button
					className={`rounded-full py-1 px-2 border-1 ${
						timePeriod === CONST_DATES.month
							? 'border-romanRed2  '
							: 'border-romanLightTextInactive'
					}  ${
						timePeriod === CONST_DATES.month
							? 'dark:border-romanGold1'
							: 'dark:border-romanDarkBorder1'
					}`}
					onClick={() => setTimePeriod(CONST_DATES.month)}
				>
					Month
				</button>
			</div>
			<ResponsiveContainer>
				<ComposedChart
					data={chartData}
					margin={{
						top: 5,
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					<CartesianGrid strokeDasharray='3 3' />
					<XAxis
						dataKey='chartDay'
						tick={{ fontSize: 10 }} // Set the font size for the X-axis labels
						// tickFormatter={(value) => {
						// 	const date = new Date(value)
						// 	return `${date.getMonth() + 1}-${date.getDate()}`
						// }}
					/>

					<YAxis tick={{ fontSize: 10 }} />
					<Tooltip content={<CustomTooltip />} />
					<Legend content={<CustomLegend />} />
					{barChartColors.map((item) => (
						<Bar
							key={item.dataKey}
							dataKey={item.dataKey}
							stackId='a'
							fill={item.color}
						/>
					))}
					{lineChartColors.map((item) => (
						<Line
							key={item.dataKey}
							dataKey={item.dataKey}
							type={'monotone'}
							stroke={item.color}
							fill={item.color}
							strokeDasharray={item.strokeDasharray}
							strokeWidth={item.strokeWidth}
						/>
					))}
					<ReferenceLine
						y={salesTarget}
						label={<CustomLabel value={salesTarget} />}
						stroke='red'
					/>
				</ComposedChart>
			</ResponsiveContainer>
		</div>
	)
}

export default ThroughputTrendChart
