//kpi-chart-bar.js
import React from 'react'
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from 'recharts'
import { CONST_COLORS } from '../../../constants/general'
const formatNameToInitials = (name) => {
	const words = name.split(' ')
	if (words.length > 1) {
		return words[0].charAt(0) + words[words.length - 1].charAt(0)
	}
	return name.charAt(0) // Fallback to the first character if there's only one word
}

const KPIBarChart = ({
	data,
	kpi,
	icon,
	dataKeyBar,
	dataKeyBarB,
	dataKeyX,
	tickFormatter,
	height,
}) => {
	const maxValue = Math.max(...data.map((item) => item[dataKeyBar])) + 2

	return (
		<div className='grid grid-cols-1 md:grid-cols-5'>
			<div className='col-span-1 md:col-span-1 text-center text-white bg-romanGray800Scorecard rounded-lg mt-8 z-10'>
				<div className='flex flex-col text-center  justify-center items-center  space-y-6 p-5'>
					<div className=' text-xl font-bold'>{kpi}</div>

					<div className='text-6xl text-romanGoldScorecard'>{icon}</div>
				</div>
			</div>
			<div className='col-span-1 md:col-span-4 text-black text-center bg-romanGray200Scorecard rounded-b-lg md:rounded-r-lg -mt-2 md:-ml-2  md:mt-8 z-8 pt-6 md:pt-0 pl-4'>
				<ResponsiveContainer width='100%' height={height || 200}>
					<BarChart
						data={data}
						margin={{ top: 20, right: 20, left: -20, bottom: 5 }}
					>
						<CartesianGrid strokeDasharray='3 3' />
						<XAxis
							dataKey={dataKeyX}
							tickFormatter={tickFormatter && formatNameToInitials}
							tick={{ fontSize: 12 }}
						/>

						<YAxis tick={{ fontSize: 12 }} domain={[0, maxValue]} />
						<Tooltip />

						<Bar dataKey={dataKeyBar} fill={CONST_COLORS.romanRedScorecard} />
						{dataKeyBarB && (
							<Bar
								dataKey={dataKeyBarB}
								fill={CONST_COLORS.romanRedScorecard}
							/>
						)}
					</BarChart>
				</ResponsiveContainer>
			</div>
		</div>
	)
}

export default KPIBarChart
