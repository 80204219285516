import axios from 'axios'
import { CONST_API } from '../../../constants/api'
import { REDUX_CONST } from '../../constants/'
import { logout } from '../userActions'
import { CONST_ERRORS } from '../../../constants/general'

export const getJobValues =
	({ jobIDs }) =>
	async (dispatch, getState) => {
		// console.log('Events:Request:GetEvents')
		try {
			dispatch({
				type: REDUX_CONST.giddyUp.jobConstants.getJobValues.request,
			})

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
				},
				params: { jobIDs },
			}

			const { data } = await axios.get(
				CONST_API.channels.giddyUp + CONST_API.routes.giddyUp.jobs.getJobValues,
				config,
			)
			// console.log('Events:Response:GetEvents')
			console.log(data)

			dispatch({
				type: REDUX_CONST.giddyUp.jobConstants.getJobValues.success,
				payload: data,
			})
		} catch (error) {
			console.log(error)
			const message =
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			if (message === CONST_ERRORS['401ExpiredToken']) {
				dispatch(logout())
			}
			dispatch({
				type: REDUX_CONST.giddyUp.jobConstants.getJobValues.fail,
				payload: message,
			})
		}
	}

export const getJobs = () => async (dispatch, getState) => {
	// console.log('Events:Request:GetEvents')
	try {
		dispatch({
			type: REDUX_CONST.giddyUp.jobConstants.getJobs.request,
		})

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(
			CONST_API.channels.giddyUp + CONST_API.routes.giddyUp.jobs.getJobs,
			config,
		)
		// console.log('Events:Response:GetEvents')
		// console.log(data)

		dispatch({
			type: REDUX_CONST.giddyUp.jobConstants.getJobs.success,
			payload: data,
		})
	} catch (error) {
		const message =
			error.response && error.response.data.message
				? error.response.data.message
				: error.message
		if (message === CONST_ERRORS['401ExpiredToken']) {
			dispatch(logout())
		}
		dispatch({
			type: REDUX_CONST.giddyUp.jobConstants.getJobs.fail,
			payload: message,
		})
	}
}
export const getJobsInfo =
	({ jobs }) =>
	async (dispatch, getState) => {
		// console.log('Events:Request:GetEvents')
		try {
			dispatch({
				type: REDUX_CONST.giddyUp.jobConstants.getJobsInfo.request,
			})

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
				},
				params: { jobs },
			}

			const { data } = await axios.get(
				CONST_API.channels.giddyUp + CONST_API.routes.giddyUp.jobs.getJobsInfo,
				config,
			)
			// console.log('Events:Response:GetEvents')
			// console.log(data)

			dispatch({
				type: REDUX_CONST.giddyUp.jobConstants.getJobsInfo.success,
				payload: data,
			})
		} catch (error) {
			const message =
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			if (message === CONST_ERRORS['401ExpiredToken']) {
				dispatch(logout())
			}
			dispatch({
				type: REDUX_CONST.giddyUp.jobConstants.getJobsInfo.fail,
				payload: message,
			})
		}
	}

export const createJob = (phase) => async (dispatch, getState) => {
	const { phase: phaseName, group, department, order } = phase
	try {
		dispatch({
			type: REDUX_CONST.giddyUp.jobConstants.createJob.request,
		})

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.post(
			CONST_API.channels.giddyUp + CONST_API.routes.giddyUp.jobs.getJobs,
			{ phase: phaseName, group, department, order },
			config,
		)

		dispatch({
			type: REDUX_CONST.giddyUp.jobConstants.createJob.success,
			payload: data,
		})

		// localStorage.setItem(CONST_LOCAL_STORAGE.userInfo, JSON.stringify(data))
	} catch (error) {
		const message =
			error.response && error.response.data.message
				? error.response.data.message
				: error.message
		if (message === CONST_ERRORS['401ExpiredToken']) {
			dispatch(logout())
		}
		dispatch({
			type: REDUX_CONST.giddyUp.jobConstants.createJob.fail,
			payload: message,
		})
	}
}

export const deletePhase = (id) => async (dispatch, getState) => {
	// console.log(id)
	try {
		dispatch({
			type: REDUX_CONST.giddyUp.phaseConstants.deletePhase.request,
		})

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.delete(
			`${
				CONST_API.channels.giddyUp + CONST_API.routes.giddyUp.phases.getPhases
			}/${id}`,
			config,
		)

		dispatch({
			type: REDUX_CONST.giddyUp.phaseConstants.deletePhase.success,
			payload: data,
		})
	} catch (error) {
		console.log('error')
		console.log(error)
		const message =
			error.response && error.response.data.message
				? error.response.data.message
				: error.message
		if (message === CONST_ERRORS['401ExpiredToken']) {
			dispatch(logout())
		}
		dispatch({
			type: REDUX_CONST.giddyUp.phaseConstants.deletePhase.fail,
			payload: message,
		})
	}
}

export const updatePhase = (phase) => async (dispatch, getState) => {
	try {
		dispatch({
			type: REDUX_CONST.giddyUp.phaseConstants.updatePhase.request,
		})

		const {
			userLogin: { userInfo },
		} = getState()
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.put(
			`${
				CONST_API.channels.giddyUp + CONST_API.routes.giddyUp.phases.getPhases
			}/${phase._id}`,
			phase,
			config,
		)

		dispatch({
			type: REDUX_CONST.giddyUp.phaseConstants.updatePhase.success,
			payload: data,
		})
	} catch (error) {
		const message =
			error.response && error.response.data.message
				? error.response.data.message
				: error.message
		if (message === CONST_ERRORS['401ExpiredToken']) {
			dispatch(logout())
		}
		dispatch({
			type: REDUX_CONST.giddyUp.phaseConstants.updatePhase.fail,
			payload: message,
		})
	}
}
