// CONSTANTS
export const CONST_DARK = 'Dark'
export const CONST_LIGHT = 'Light'
export const CONST_START = 'Start'
export const CONST_STOP = 'Stop'
export const CONST_SUCCESS = 'Success'
export const CONST_FAIL = 'Fail'
export const CONST_ERROR = 'Error'
export const CONST_PREV = 'Previous'
export const CONST_NEXT = 'Next'
export const CONST_BUTTON = 'label'
export const CONST_LABEL = 'button'
export const CONST_EDIT = 'edit'
export const CONST_ADD = 'add'
export const CONST_DELETE = 'delete'

export const GEN_CONST = {
	dark: CONST_DARK,
	light: CONST_LIGHT,
	start: CONST_START,
	stop: CONST_STOP,
	success: CONST_SUCCESS,
	fail: CONST_FAIL,
	error: CONST_ERROR,
	previous: CONST_PREV,
	next: CONST_NEXT,
	label: CONST_LABEL,
	button: CONST_BUTTON,
	edit: CONST_EDIT,
	add: CONST_ADD,
	delete: CONST_DELETE,
	types: {
		number: 'Number',
		percent: 'Percent',
	},
}

export const CONST_DATES = {
	all: 'allDates',
	today: 'today',
	thisWeek: 'thisWeek',
	thisMonth: 'thisMonth',
	last7Days: 'last7Days',
	last30Days: 'last30Days',
	last90Days: 'last90Days',
	last180Days: 'last180Days',
	thisQuarter: 'thisQuarter',
	thisYear: 'thisYear',
	thisYearToDate: 'thisYearToDate',
	lastYear: 'lastYear',
	lastYearToDate: 'lastYearToDate',
	day: 'Day',
	week: 'Week',
	month: 'Month',
}
export const CONST_ERRORS = {
	'401ExpiredToken': 'Not authorized, token failed',
	authorization: {
		402: 'Unauthorized: Tokens do not match.',
		403: 'Unauthorized: Missing token.',
	},
}

export const CONST_MONGO_DB_FIELDS = {
	createdAt: { alias: 'createdAt', name: 'Date' },
	jobID: { alias: 'jobID', name: 'Job #' },
	eventContact: { alias: 'eventContact', name: 'Created By' },
	eventType: { alias: 'eventType', name: 'Type' },
	leadSource: { alias: 'leadSource', name: 'Lead Source' },
	leadType: { alias: 'leadType', name: 'Lead Type' },
	jobType: { alias: 'jobType', name: 'Job Type' },
	phase: { alias: 'phase', name: 'Job Phase' },
	template: { alias: 'template', name: 'Template Used' },
}

export const CONST_COLORS = {
	darkBlue: 'hsl(217, 28%, 15%)',
	darkBlue1: 'hsl(218, 28%, 13%)',
	darkBlue2: 'hsl(216, 53%, 9%)',
	darkBlue3: 'hsl(219, 30%, 18%)',
	// romanRed2  : 'hsl(176, 68%, 64%)',
	accentBlue: 'hsl(198, 60%, 50%)',
	lightRed: 'hsl(0, 100%, 63%)',
	cyan: 'hsl(180, 66%, 49%)',
	cyanLight: 'hsl(180, 66%, 69%)',
	darkViolet: 'hsl(257, 27%, 26%)',
	red: 'hsl(0, 87%, 67%)',
	grayishViolet: 'hsl(257, 7%, 63%)',
	veryDarkBlue: 'hsl(255, 11%, 22%)',
	veryDarkViolet: 'hsl(260, 8%, 14%)',
	romanRedScorecard: 'hsl(0deg 99% 34%)',
	romanGoldScorecard: 'hsl(50deg 91% 46%)',
	romanGray200Scorecard: 'hsl(0deg 0% 96%)',
	romanGray800Scorecard: 'hsl(0deg 0% 33%)',
	romanRed: 'hsl(1deg 100% 50%)',
	// romanRed2  : 'hsl(0deg 99% 34%)',
	romanRed1: 'hsl(0deg 100% 20%)',
	romanRed2: 'hsl(0deg 100% 30%)',
	romanRed3: 'hsl(0deg 100% 40%)',
	romanRed4: 'hsl(0deg 67% 64%)',
	romanRed5: 'hsl(0deg 66% 76%)',
	romanRed6: 'hsl(359deg 64% 88%)',
	romanGold1: 'hsl(45deg 87% 57%)',
	romanGold2: 'hsl(45deg 100% 70%)',
	romanGold3: 'hsl(45deg 100% 80%)',
	romanGold4: 'hsl(45deg 100% 90%)',
	romanYellow: 'hsl(60deg 100% 50%)',
	romanLight1: 'hsl(0deg 0% 100%)',
	romanLight2: 'hsl(360deg 0% 97%)',
	romanLight3: 'hsl(360deg 0% 92%)',
	romanLight4: 'hsl(360deg 0% 88%)',
	romanLightInactive: 'hsl(0deg 0% 38%)',
	romanLightBorder1: 'hsl(360deg 0% 90%)',
	romanLightTextInactive: 'hsl(0deg 0% 75%)',
	romanLightTextActive: 'hsl(0deg 0% 12%)',
	romanDark1: 'hsl(0deg 0% 9%)',
	romanDark2: 'hsl(0deg 0% 12%)',
	romanDark3: 'hsl(0deg 0% 16%)',
	romanDark4: 'hsl(0deg 0% 19%)',
	romanDarkInactive: 'hsl(0deg 0% 24%)',
	romanDarkBorder1: 'hsl(0deg 0% 28%)',
	romanDarkTextInactive: 'hsl(0deg 0% 49%)',
	romanDarkTextActive: 'hsl(0deg 0% 84%)',
}

export const CONST_ROMAN_FACTORS = {
	leadToSaleConversion: 0.5,
}

export const CONST_UNITS = {
	dollar: 'DOLLAR',
	percent: 'PERCENT',
}

// targets are always weekly targets.  the math is done on the front end to convert this to the appropriate time.
// If we know the sales throughput desired is 50 reroofs, we can divide this number up into our estimators.  This
// will give us the team goal too.
export const CONST_ESTIMATORS_TARGETS = [
	{ name: 'Person A', target: 4, team: 'teamAlpha' },
	{ name: 'Person B', target: 3, team: 'teamAlpha' },
	{ name: 'Person C', target: 3, team: 'teamAlpha' },
	{ name: 'Person D', target: 5, team: 'teamBeta' },
	{ name: 'Person E', target: 4, team: 'teamBeta' },
	{ name: 'Person F', target: 3, team: 'teamBeta' },
]

export const CONST_SALES_TEAMS_TARGETS = [
	{ name: 'teamAlpha', target: 10 },
	{ name: 'teamBeta', target: 12 },
]

export const CONST_ROMAN_KPI = {
	salesThroughput: {
		leadsGenerated: {
			eventType: 'leadsGenerated',
			alias: 'leadsGenerated',
			chartName: 'Leads Generated',
		},
		appointmentsSet: {
			eventType: 'appointmentsSet',
			alias: 'appointmentsSet',
			chartName: 'Appointments Set',
		},
		appointmentsRan: {
			eventType: 'appointmentsRan',
			alias: 'appointmentsRan',
			chartName: 'Appointments Ran',
		},
		insuranceWork: {
			eventType: 'insuranceWork',
			alias: 'insuranceWork',
			chartName: 'Insurance',
		},
		repairs: { eventType: 'repairs', alias: 'repairs', chartName: 'Repairs' },
		reroof: { eventType: 'reroof', alias: 'reroof', chartName: 'Reroof' },
		target: 50,
	},
	nonDigitalLeadGenerated: {
		eventType: 'nonDigitalLeadGenerated',
		alias: 'nonDigitalLeadCreated',
		chartName: 'Lead Created',
		department: 'Marketing',
	},
	nonDigitalLeadCreated: {
		eventType: 'nonDigitalLeadCreated',
		alias: 'nonDigitalLeadCreated',
		chartName: 'Lead Created',
		department: 'Marketing',
	},
	digitalLeadGenerated: {
		eventType: 'digitalLeadGenerated',
		alias: 'digitalLeadGenerated',
		chartName: 'Digital Lead',
		department: 'Marketing',
	},
	salesmanLeadCreated: {
		eventType: 'salesmanLeadCreated',
		alias: 'salesmanLeadCreated',
		chartName: 'Salesman Lead',
		department: 'Marketing',
	},
	initialAppointmentSet: {
		eventType: 'initialAppointmentSet',
		alias: 'appointmentSet',
		chartName: 'Appointment Set',
		department: 'Marketing',
	},

	leftVoicemail: {
		eventType: 'leftVoicemail',
		alias: 'leftVoicemail',
		chartName: 'Voicemail',
		department: 'Customer Care',
	},
	phoneCall: {
		eventType: 'phoneCall',
		alias: 'phoneCall',
		chartName: 'Phone Call',
		department: 'Customer Care',
	},
	phoneCallMade: {
		eventType: 'phoneCallMade',
		alias: 'phoneCall',
		chartName: 'Phone Call',
		department: 'Customer Care',
	},
	internalAudit: {
		eventType: 'internalAudit',
		alias: 'internalAudit',
		chartName: 'Internal Audit Entered?',
		department: 'Pre-Production',
	},
	jobFileAudited: {
		eventType: 'jobFileAudited',
		alias: 'jobFileAudited',
		chartName: 'File Audited',
		department: 'Pre-Production',
	},
	permitApplied: {
		eventType: 'permitApplied',
		alias: 'permitApplied',
		chartName: 'Applied for Permit',
		department: 'Pre-Production',
	},
	permitReceived: {
		eventType: 'permitReceived',
		alias: 'permitReceived',
		chartName: 'Permit Received',
		department: 'Pre-Production',
	},
	waitingForPermitting: {
		eventType: 'waitingForPermitting',
		alias: 'waitingForPermitting',
		chartName: 'Waiting For Permitting?',
		department: 'Pre-Production',
	},
	jobCompleted: {
		eventType: 'jobCompleted',
		alias: 'jobCompleted',
		chartName: 'Job Completed',
		department: 'Production',
	},
	jobScheduled: {
		eventType: 'jobScheduled',
		alias: 'jobScheduled',
		chartName: 'Job Scheduled',
		department: 'Production',
	},
	repairComplete: {
		eventType: 'repairComplete',
		alias: 'repairComplete',
		chartName: 'Repair Complete',
		department: 'Production',
	},
	repairScheduled: {
		eventType: 'repairScheduled',
		alias: 'repairScheduled',
		chartName: 'Repair Scheduled',
		department: 'Production',
	},
	appointmentSet: {
		eventType: 'appointmentSet',
		alias: 'appointmentSet',
		chartName: 'Appointment Set',
		department: 'Sales',
	},
	contractSigned: {
		eventType: 'contractSigned',
		alias: 'contractSigned',
		chartName: 'Contract Signed',
		department: 'Sales',
	},
	estimateSent: {
		eventType: 'estimateSent',
		alias: 'estimateSent',
		chartName: 'Estimate Sent',
		department: 'Sales',
	},
	initialAppointmentCompleted: {
		eventType: 'initialAppointmentCompleted',
		alias: 'initialAppointmentCompleted',
		chartName: 'Appointment Ran',
		department: 'Sales',
	},
	leadContactAttempted: {
		eventType: 'leadContactAttempted',
		alias: 'leadContactAttempted',
		chartName: 'Lead Contacted',
		department: 'Sales',
	},
	leadEmailAttempted: {
		eventType: 'leadEmailAttempted',
		alias: 'leadEmailAttempted',
		chartName: 'Lead Emailed',
		department: 'Sales',
	},
	leadPhoneCallAttempted: {
		eventType: 'leadPhoneCallAttempted',
		alias: 'leadPhoneCallAttempted',
		chartName: 'Lead Phoned',
		department: 'Sales',
	},
	solarSold: {
		eventType: 'solarSold',
		alias: 'solarSold',
		chartName: 'Solar Job Sold',
		department: 'Sales',
	},
	submittedToAudit: {
		eventType: 'submittedToAudit',
		alias: 'submittedToAudit',
		chartName: 'File Submitted To Audit',
		department: 'Sales',
	},
}
