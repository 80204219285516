//score.js
import React from 'react'

const TheScore = ({ total, target }) => {
	return (
		<div className=''>{`$${parseInt(total).toLocaleString()} : ${parseInt(
			(total / target) * 100,
		)}% to goal`}</div>
	)
}

export default TheScore
