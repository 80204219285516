// event-content.js
import React, { useEffect, useState } from 'react'
import EventList from './event-list'
import EventsSummary from './events-summary'
import { useDispatch, useSelector } from 'react-redux'
import { getEvents } from '../../../redux/actions'
import { PulseReportLinks, PageWrapper } from '../../../components'

const EventContent = () => {
	const dispatch = useDispatch()
	const eventsRdx = useSelector((state) => state.events)

	const [events, setEvents] = useState([])

	// useEffect(() => {
	// 	dispatch(getEvents())

	// 	return () => {}
	// }, [])

	// @todo :: Update this to run off of a context.  after success, fire off after 15 seconds.
	// useEffect(() => {
	// 	const fetchEvents = async () => {
	// 		dispatch(getEvents())
	// 	}

	// 	fetchEvents()
	// 	const intervalId = setInterval(fetchEvents, 30000) // fetches every 30 seconds

	// 	return () => clearInterval(intervalId)
	// }, [])

	useEffect(() => {
		if (eventsRdx && eventsRdx.data && eventsRdx.data.length > 0) {
			setEvents(eventsRdx.data)
		} else if (eventsRdx && eventsRdx.error) {
			setEvents(['Error Loading Events'])
		}

		return () => {}
	}, [eventsRdx])

	return (
		<PageWrapper links={<PulseReportLinks />}>
			{events.length === 0 && <div>No Events</div>}
			{events.length > 0 && (
				<div className='flex flex-col items-center space-y-32'>
					<EventsSummary events={events} />
					<EventList events={events} />
				</div>
			)}
		</PageWrapper>
	)
}

export default EventContent
