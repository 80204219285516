//admin-links.js
import React from 'react'
import { ROUTES } from '../../../constants'
import { Link } from 'react-router-dom'

const AdminLinks = () => {
	return (
		<React.Fragment>
			<Link
				to={`${ROUTES.pages.admin.home + ROUTES.pages.admin.users}`}
				className={
					'mx-4 text-center rounded-theme-rounding bg-romanRed2   dark:bg-romanGold1 py-2 px-4 text-romanLight1 dark:text-romanDark4 hover:opacity-80 hover:-translate-y-0.5'
				}
			>
				Users
			</Link>
			<Link
				to={`${ROUTES.pages.admin.home + ROUTES.pages.admin.salesAnalysis}`}
				className={
					'mx-4 text-center rounded-theme-rounding bg-romanRed2   dark:bg-romanGold1 py-2 px-4 text-romanLight1 dark:text-romanDark4 hover:opacity-80 hover:-translate-y-0.5'
				}
			>
				Ashborne Reconciliation
			</Link>

			<Link
				to={`${ROUTES.pages.admin.home + ROUTES.pages.admin.phases}`}
				className={
					'mx-4 text-center rounded-theme-rounding bg-romanRed2   dark:bg-romanGold1 py-2 px-4 text-romanLight1 dark:text-romanDark4 hover:opacity-80 hover:-translate-y-0.5'
				}
			>
				*Phases*
			</Link>
			<Link
				to={`${ROUTES.pages.admin.home + ROUTES.pages.admin.departments}`}
				className={
					'mx-4 text-center rounded-theme-rounding bg-romanRed2   dark:bg-romanGold1 py-2 px-4 text-romanLight1 dark:text-romanDark4 hover:opacity-80 hover:-translate-y-0.5'
				}
			>
				*Departments*
			</Link>
		</React.Fragment>
	)
}

export default AdminLinks
