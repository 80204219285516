//throughput-trend.js
import React, { useEffect, useState } from 'react'
import {
	CONST_COLORS,
	CONST_DATES,
	CONST_ROMAN_KPI,
} from '../../../../constants/general'
import GridWrapper from '../../grid-wrapper'
import ShortChartTrend from './throughput-trend-chart'

const MenuContent = ({ onChangeRange, timeRange }) => {
	return (
		<div className='text-xs flex justify-evenly space-x-2 items-center'>
			<div
				onClick={() => onChangeRange(CONST_DATES.last7Days)}
				className={`px-2 py-1 border-1 ${
					timeRange.range === CONST_DATES.last7Days
						? 'border-romanRed2  '
						: 'border-romanLightTextInactive'
				}  ${
					timeRange.range === CONST_DATES.last7Days
						? 'dark:border-romanGold1'
						: 'dark:border-romanDarkBorder1'
				}  rounded-full `}
			>
				7 days
			</div>
			<div
				onClick={() => onChangeRange(CONST_DATES.last30Days)}
				className={`px-2 py-1 border-1 ${
					timeRange.range === CONST_DATES.last30Days
						? 'border-romanRed2  '
						: 'border-romanLightTextInactive'
				}  ${
					timeRange.range === CONST_DATES.last30Days
						? 'dark:border-romanGold1'
						: 'dark:border-romanDarkBorder1'
				}  rounded-full `}
			>
				30 days
			</div>
			<div
				onClick={() => onChangeRange(CONST_DATES.last90Days)}
				className={`px-2 py-1 border-1 ${
					timeRange.range === CONST_DATES.last90Days
						? 'border-romanRed2  '
						: 'border-romanLightTextInactive'
				}  ${
					timeRange.range === CONST_DATES.last90Days
						? 'dark:border-romanGold1'
						: 'dark:border-romanDarkBorder1'
				}  rounded-full `}
			>
				90 days
			</div>
			<div
				onClick={() => onChangeRange(CONST_DATES.last180Days)}
				className={`px-2 py-1 border-1 ${
					timeRange.range === CONST_DATES.last180Days
						? 'border-romanRed2  '
						: 'border-romanLightTextInactive'
				}  ${
					timeRange.range === CONST_DATES.last180Days
						? 'dark:border-romanGold1'
						: 'dark:border-romanDarkBorder1'
				}  rounded-full `}
			>
				180 days
			</div>
		</div>
	)
}

// with leadsData
// if the event.eventType === any of the following:
// nonDigitalLeadGenerated
// nonDigitalLeadCreated
// digitalLeadGenerated
// salesmanLeadCreated
// then change the property to event.eventType='Leads Generated'

const lineChartColors = [
	{
		dataKey: CONST_ROMAN_KPI.salesThroughput.leadsGenerated.chartName,
		color: CONST_COLORS.romanRed1,
		strokeWidth: 2,
		strokeDasharray: 'none',
	},
	{
		dataKey: CONST_ROMAN_KPI.salesThroughput.appointmentsSet.chartName,
		color: CONST_COLORS.romanRed2,
		strokeWidth: 2,
		strokeDasharray: '15 5 5 5',
	},
	{
		dataKey: CONST_ROMAN_KPI.salesThroughput.appointmentsRan.chartName,
		color: CONST_COLORS.romanRed5,
		strokeWidth: 1,
		strokeDasharray: '3 5',
	},
]

// with salesData
// event.template =-> potential values:
// Inbound Digital Lead Triage
// Inbound Lead Triage
// Insurance Work
// Repairs
// Residential Retail

// Map event.template === 'Inbound Digital Lead Triage' OR event.template=== 'Inbound Lead Triage' into event.template= 'Inbound Lead'
// Map event.template === 'Residential Retail' into event.template = 'Reroof'
const barChartColors = [
	{
		dataKey: CONST_ROMAN_KPI.salesThroughput.reroof.chartName,
		color: CONST_COLORS.romanGold1,
	},

	{
		dataKey: CONST_ROMAN_KPI.salesThroughput.repairs.chartName,
		color: CONST_COLORS.romanRed2,
	},

	{
		dataKey: CONST_ROMAN_KPI.salesThroughput.insuranceWork.chartName,
		color: CONST_COLORS.romanDarkTextInactive,
	},
]

const ThroughputTrend = ({ salesRdx, onGetData, leadsRdx }) => {
	const [timeRange, setTimeRange] = useState({
		range: CONST_DATES.last90Days,
		title: 'Previous 90 Days',
	})
	const [chartData, setChartData] = useState([])

	const changeRangeHandler = (key) => {
		switch (key) {
			case CONST_DATES.last90Days:
				setTimeRange({
					range: CONST_DATES.last90Days,
					title: 'Previous 90 Days',
				})
				break
			case CONST_DATES.last30Days:
				setTimeRange({
					range: CONST_DATES.last30Days,
					title: 'Previous 30 Days',
				})
				break
			case CONST_DATES.last180Days:
				setTimeRange({
					range: CONST_DATES.last180Days,
					title: 'Previous 180 Days',
				})
				break
			case CONST_DATES.last7Days:
				setTimeRange({ range: CONST_DATES.last7Days, title: 'Previous 7 Days' })
				break

			default:
				break
		}
		onGetData(key)
	}

	useEffect(() => {
		// console.log(salesRdx)
		// console.log(leadsRdx)

		// Dynamically determine the data key based on the selected time range
		const salesDataKey = `sales_${timeRange.range.replace('last', 'last')}`
		const salesData = salesRdx[salesDataKey] || []
		const leadsDataKey = `leads_${timeRange.range.replace('last', 'last')}`
		const leadsData = leadsRdx[leadsDataKey] || []

		// Function to map the data based on the specified criteria
		const mapData = (data) => {
			// console.log(data)
			return data.map((event) => {
				// Mapping for leadsData
				if (
					[
						'nonDigitalLeadGenerated',
						'nonDigitalLeadCreated',
						'digitalLeadGenerated',
						'salesmanLeadCreated',
					].includes(event.eventType)
				) {
					event.eventType = 'leadsGenerated'
				}

				// Mapping for salesData
				// Inbound Digital Lead Triage
				// Inbound Lead Triage

				// Insurance Work

				// Repairs

				// Residential Retail
				if (event.eventType === 'appointmentSet') {
					// console.log(event.jobID)
					event.eventType = 'appointmentsSet'
				}
				if (event.eventType === 'initialAppointmentCompleted') {
					event.eventType = 'appointmentsRan'
				}

				if (
					(event.eventType === 'solarSold' ||
						event.eventType === 'contractSigned') &&
					event.template === 'Residential Retail'
				) {
					event.eventType = 'reroof'
				} else if (
					(event.eventType === 'solarSold' ||
						event.eventType === 'contractSigned') &&
					event.template === 'Repairs'
				) {
					event.eventType = 'repairs'
				} else if (
					(event.eventType === 'solarSold' ||
						event.eventType === 'contractSigned') &&
					event.template === 'Insurance Work'
				) {
					event.eventType = 'insuranceWork'
				}

				return event
			})
		}

		const combinedData = [...salesData, ...leadsData]
		const chartArray = mapData(combinedData)

		// Now you have the mapped chartArray ready for the component
		// console.log('Mapped chart array: ', chartArray)
		setChartData(chartArray)

		return () => {}
	}, [salesRdx, leadsRdx])

	return (
		<GridWrapper
			firstLine={'Trend'}
			secondLine={timeRange.title}
			colSpan={' md:col-span-3 '}
			rightContent={
				<MenuContent timeRange={timeRange} onChangeRange={changeRangeHandler} />
			}
		>
			<ShortChartTrend
				barChartColors={barChartColors}
				lineChartColors={lineChartColors}
				rawData={chartData}
			/>
		</GridWrapper>
	)
}

export default ThroughputTrend
