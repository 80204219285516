//pulse-report-links.js
import React from 'react'
import { ROUTES } from '../../../constants'
import { Link } from 'react-router-dom'

const PulseReportLinks = () => {
	return (
		<React.Fragment>
			<Link
				to={`${ROUTES.pages.pulseReport.home}`}
				className={
					'mx-4 text-center rounded-theme-rounding bg-romanRed2   dark:bg-romanGold1 py-2 px-4 text-romanLight1 dark:text-romanDark4 hover:opacity-80 hover:-translate-y-0.5'
				}
			>
				Pulse Home
			</Link>
			{/* <Link
				to={`${
					ROUTES.pages.pulseReport.home + ROUTES.pages.pulseReport.events
				}`}
				className={
					'mx-4 text-center rounded-theme-rounding bg-romanRed2   dark:bg-romanGold1 py-2 px-4 text-romanLight1 dark:text-romanDark4 hover:opacity-80 hover:-translate-y-0.5'
				}
			>
				Events
			</Link> */}

			<Link
				to={`${
					ROUTES.pages.pulseReport.home + ROUTES.pages.pulseReport.marketing
				}`}
				className={
					'mx-4 text-center rounded-theme-rounding bg-romanRed2   dark:bg-romanGold1 py-2 px-4 text-romanLight1 dark:text-romanDark4 hover:opacity-80 hover:-translate-y-0.5'
				}
			>
				Marketing
			</Link>
			<Link
				to={`${
					ROUTES.pages.pulseReport.home + ROUTES.pages.pulseReport.customerCare
				}`}
				className={
					'mx-4 text-center rounded-theme-rounding bg-romanRed2   dark:bg-romanGold1 py-2 px-4 text-romanLight1 dark:text-romanDark4 hover:opacity-80 hover:-translate-y-0.5'
				}
			>
				Old Marketing View
			</Link>
			<Link
				to={`${ROUTES.pages.pulseReport.home + ROUTES.pages.pulseReport.sales}`}
				className={
					'mx-4 text-center rounded-theme-rounding bg-romanRed2   dark:bg-romanGold1 py-2 px-4 text-romanLight1 dark:text-romanDark4 hover:opacity-80 hover:-translate-y-0.5'
				}
			>
				Sales
			</Link>
			<Link
				to={`${
					ROUTES.pages.pulseReport.home + ROUTES.pages.pulseReport.preProduction
				}`}
				className={
					'mx-4 text-center rounded-theme-rounding bg-romanRed2   dark:bg-romanGold1 py-2 px-4 text-romanLight1 dark:text-romanDark4 hover:opacity-80 hover:-translate-y-0.5'
				}
			>
				Old Sales View
			</Link>
			{/* <Link
				to={`${
					ROUTES.pages.pulseReport.home + ROUTES.pages.pulseReport.production
				}`}
				className={
					'mx-4 text-center rounded-theme-rounding bg-romanRed2   dark:bg-romanGold1 py-2 px-4 text-romanLight1 dark:text-romanDark4 hover:opacity-80 hover:-translate-y-0.5'
				}
			>
				Production
			</Link>
			<Link
				to={`${
					ROUTES.pages.pulseReport.home + ROUTES.pages.pulseReport.collections
				}`}
				className={
					'mx-4 text-center rounded-theme-rounding bg-romanRed2   dark:bg-romanGold1 py-2 px-4 text-romanLight1 dark:text-romanDark4 hover:opacity-80 hover:-translate-y-0.5'
				}
			>
				Collections
			</Link> */}
		</React.Fragment>
	)
}

export default PulseReportLinks
