//estimates-table.js
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import io from 'socket.io-client'

import { getEstimates } from '../../../redux/actions'

const EstimatesTable = () => {
	const dispatch = useDispatch()
	const [tableData, setTableData] = useState([])

	const estimatesRdx = useSelector((state) => state.estimates)

	//PROCESS DATA FROM USE EFFECT
	const processEstimateData = (data) => {
        const processedData = data
		return processedData
	}

	// GET DATA ON RENDER

	useEffect(() => {
		dispatch(
			getEstimates({
				// phaseChanges,
				// eventTypes,
				// key: 'sales',
				// weekOffset,
			}),
		)
	}, [dispatch])

	// CONNECT TO SOCKET FOR UPDATES

	useEffect(() => {
		// io('http://localhost:5000')
		// Connect to the Socket.IO server
		// Replace 'http://localhost:5000' with your server's URL
		const socket = io('https://www.nvoqe.com', { transports: ['websocket'] })
		// Setup event listeners

		socket.on('ESTIMATES', (data) => {
			if (data.action === 'ESTIMATE_UPDATED') {
				console.log(data.payload)
				// const event = data.payload
				// if (
				// 	event.eventType === 'initialAppointmentCompleted' ||
				// 	event.eventType === 'solarSold' ||
				// 	event.eventType === 'contractSigned' ||
				// 	(event.eventType === 'phaseChange' &&
				// 		event.phase === 'Contract Signed')
				// ) {
				// 	if (weekOffset === 0) {
				// 		dispatch(
				// 			getScorecardData({
				// 				phaseChanges,
				// 				eventTypes,
				// 				key: 'sales',
				// 			}),
				// 		)
				// 	}
				// 	dispatch(
				// 		getLeaderboardData({
				// 			phaseChanges,
				// 			eventTypes,
				// 			key: 'sales',
				// 		}),
				// 	)
				// }
			}
		})
		// Clean up the effect
		return () => socket.disconnect()
	}, [])

	useEffect(() => {
		console.log(estimatesRdx)
		if (estimatesRdx && estimatesRdx.data) {
			setTableData(processEstimateData(estimatesRdx.data))
		} else {
			setTableData([])
		}
	}, [estimatesRdx])

	useEffect(() => {
		console.log(tableData)

		return () => {}
	}, [tableData])

	return <div>EstimatesTable</div>
}

export default EstimatesTable
