import React from 'react'
import { PageWrapper, ScorecardHeader, ExecutiveLinks } from '../../components'

const Executive = () => {
	return (
		<PageWrapper title='Financials' links={<ExecutiveLinks />}>
			<ScorecardHeader title='Executive Dashboard' />
		</PageWrapper>
	)
}
export default Executive
