//sales-rep-chart.js
import React, { useEffect, useState } from 'react'
import {
	ResponsiveContainer,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	BarChart,
} from 'recharts'

import { CONST_DATES, CONST_ROMAN_KPI } from '../../../../constants/general'

// const CustomLabel = ({ viewBox, value }) => {
// 	const { x, y } = viewBox
// 	return (
// 		<text x={x} y={y} dy={-4} fill='red' fontSize={10} textAnchor='start'>
// 			{`Sales Target = ${value}`}
// 		</text>
// 	)
// }

// const CustomLegend = (props) => {
// 	const { payload } = props

// 	const barItems = payload.filter((item) =>
// 		['Reroof', 'Repairs', 'Insurance'].includes(item.value),
// 	)
// 	const lineItems = payload.filter(
// 		(item) => !['Reroof', 'Repairs', 'Insurance'].includes(item.value),
// 	)

// 	return (
// 		<div className='flex flex-col space-y-3 text-xs'>
// 			<div className='flex space-x-3 items-center justify-center'>
// 				<div className='text-lg font-semibold'>{ICONS_CHARTS.bar2}</div>
// 				<div className='flex space-x-2'>
// 					{barItems.map((entry, index) => (
// 						<div key={`item-${index}`} style={{ color: entry.color }}>
// 							{entry.value}
// 						</div>
// 					))}
// 				</div>
// 			</div>
// 			<div className='flex space-x-3 items-center justify-center'>
// 				<div className='text-lg font-semibold'>{ICONS_CHARTS.line}</div>
// 				<div className='flex space-x-2'>
// 					{lineItems.map((entry, index) => (
// 						<div key={`item-${index}`} style={{ color: entry.color }}>
// 							{entry.value}
// 						</div>
// 					))}
// 				</div>
// 			</div>
// 		</div>
// 	)
// }
// const CustomTooltip = ({ active, payload }) => {
// 	if (active && payload && payload.length) {
// 		const payloadMapped = payload.map((entry) => {
// 			let newEntry = { ...entry }
// 			switch (entry.dataKey) {
// 				case 'Reroof':
// 				case 'Repairs':
// 				case 'Insurance':
// 					newEntry.chartType = 'Bar'
// 					break

// 				default:
// 					newEntry.chartType = 'Line'
// 					break
// 			}
// 			return newEntry
// 		})

// 		// Grouping the payload by chartType
// 		const groupedByType = {
// 			Bar: payloadMapped.filter((entry) => entry.chartType === 'Bar'),
// 			Line: payloadMapped.filter((entry) => entry.chartType === 'Line'),
// 		}

// 		return (
// 			<div className='outline-none p-2 bg-romanLight3 dark:bg-romanDark3 bg-opacity-80 dark:bg-opacity-50 text-xs'>
// 				{Object.entries(groupedByType).map(([type, items]) => (
// 					<div key={type}>
// 						<div className='font-bold underline'>{type} Chart:</div>
// 						<ul>
// 							{items.map((entry, index) => (
// 								<li
// 									className='pl-2'
// 									style={{ color: entry.color }}
// 									key={`item-${index}`}
// 								>
// 									{`${entry.name} : ${entry.value}`}
// 								</li>
// 							))}
// 						</ul>
// 					</div>
// 				))}
// 			</div>
// 		)
// 	}
// 	return null
// }

const SalesRepBarChart = ({ rawData, barChartColors, timeRange }) => {
	const [chartData, setChartData] = useState([])
	const [timePeriod, setTimePeriod] = useState(CONST_DATES.week)

	useEffect(() => {
		if (rawData && rawData.length > 0) {
			const processDataForChart = (rawData, kpiConstants) => {
				// Create a map for easy lookup of chart names using event types
				const eventTypeToChartName = Object.values(kpiConstants).reduce(
					(acc, category) => {
						Object.values(category).forEach((kpi) => {
							if (kpi.eventType) {
								acc[kpi.eventType] = kpi.chartName
							}
						})
						return acc
					},
					{},
				)

				// Initialize an object to hold the counts for each salesRep
				const salesRepCounts = {}

				// Process each event in the raw data
				rawData.forEach((event) => {
					const { eventType, eventContact: salesRep } = event
					const chartName = eventTypeToChartName[eventType]

					// Only proceed if the event type is one we're counting
					if (chartName) {
						// Initialize the salesRep in the counts object if not already present
						if (!salesRepCounts[salesRep]) {
							salesRepCounts[salesRep] = {}
						}

						// Initialize the count for this chartName for the salesRep if not already present
						if (!salesRepCounts[salesRep][chartName]) {
							salesRepCounts[salesRep][chartName] = 0
						}

						// Increment the count for this chartName for the salesRep
						salesRepCounts[salesRep][chartName]++
					}
				})

				// Convert the counts object to an array format suitable for the chart
				const chartData = Object.entries(salesRepCounts).map(
					([salesRep, counts]) => ({
						salesRep,
						...counts,
					}),
				)

				return chartData
			}

			// Usage:
			const chartData = processDataForChart(rawData, CONST_ROMAN_KPI)

			setChartData(chartData)
		} else {
			setChartData([])
		}
	}, [rawData, timeRange])

	return (
		<div style={{ width: '100%', height: 300 }} className='grow mt-10'>
			<div className='flex space-x-2 py-1 px-2 justify-end text-xs'>
				<button
					className={`rounded-full py-1 px-2 border-1 ${
						timePeriod === CONST_DATES.day
							? 'border-romanRed2  '
							: 'border-romanLightTextInactive'
					}  ${
						timePeriod === CONST_DATES.day
							? 'dark:border-romanGold1'
							: 'dark:border-romanDarkBorder1'
					}`}
					onClick={() => setTimePeriod(CONST_DATES.day)}
				>
					Day
				</button>
				<button
					className={`rounded-full py-1 px-2 border-1 ${
						timePeriod === CONST_DATES.week
							? 'border-romanRed2  '
							: 'border-romanLightTextInactive'
					}  ${
						timePeriod === CONST_DATES.week
							? 'dark:border-romanGold1'
							: 'dark:border-romanDarkBorder1'
					}`}
					onClick={() => setTimePeriod(CONST_DATES.week)}
				>
					Week
				</button>
				<button
					className={`rounded-full py-1 px-2 border-1 ${
						timePeriod === CONST_DATES.month
							? 'border-romanRed2  '
							: 'border-romanLightTextInactive'
					}  ${
						timePeriod === CONST_DATES.month
							? 'dark:border-romanGold1'
							: 'dark:border-romanDarkBorder1'
					}`}
					onClick={() => setTimePeriod(CONST_DATES.month)}
				>
					Month
				</button>
			</div>
			<ResponsiveContainer>
				<BarChart data={chartData}>
					<CartesianGrid strokeDasharray='3 3' />
					<XAxis dataKey='salesRep' tick={{ fontSize: 10 }} />
					<YAxis tick={{ fontSize: 10 }} />
					<Tooltip />
					<Legend />
					{/* <Tooltip content={<CustomTooltip />} />
					<Legend content={<CustomLegend />} /> */}
					{/* <Bar dataKey='appointmentsRan' fill='#8884d8' />
					<Bar dataKey='contractSigned' fill='#82ca9d' /> */}
					{barChartColors.map((item) => (
						<Bar
							key={item.dataKey}
							dataKey={item.dataKey}
							// stackId='a'
							fill={item.color}
						/>
					))}
				</BarChart>
			</ResponsiveContainer>
		</div>
	)
}

export default SalesRepBarChart
