import axios from 'axios'
import { CONST_API } from '../../../constants/api'
import { hrConstants } from '../../constants/hrConstants'

import { logout } from '../userActions'
import { CONST_ERRORS } from '../../../constants/general'

export const resetLevelItem = (key) => async (dispatch) => {
	switch (key) {
		case 'levelCreate':
			dispatch({ type: hrConstants.levelConstants.levelCreate.reset })

			break
		case 'levelUpdate':
			dispatch({ type: hrConstants.levelConstants.levelUpdate.reset })

			break
		case 'levelDelete':
			dispatch({ type: hrConstants.levelConstants.levelDelete.reset })

			break
		default:
			break
	}
}

export const levelList = () => async (dispatch, getState) => {
	//console.log('LevelList')
	try {
		dispatch({ type: hrConstants.levelConstants.levelList.request })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(
			CONST_API.channels.hr + CONST_API.routes.hr.levels,
			config,
		)

		dispatch({
			type: hrConstants.levelConstants.levelList.success,
			payload: data,
		})
	} catch (error) {
		const message =
			error.response && error.response.data.message
				? error.response.data.message
				: error.message
		if (message === CONST_ERRORS['401ExpiredToken']) {
			dispatch(logout())
		}
		dispatch({
			type: hrConstants.levelConstants.levelList.fail,
			payload: message,
		})
	}
}

export const levelCreate =
	({ name, minTarget, maxTarget, targetBonus, position }) =>
	async (dispatch, getState) => {
		try {
			dispatch({ type: hrConstants.levelConstants.levelCreate.request })

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
				},
			}

			// // COMPLETE_ME CHECK FOR DUPLICATION ???
			const { data } = await axios.post(
				CONST_API.channels.hr + CONST_API.routes.hr.levels,
				{ name, minTarget, maxTarget, targetBonus, position },
				config,
			)

			dispatch({
				type: hrConstants.levelConstants.levelCreate.success,
				payload: data,
			})
		} catch (error) {
			const message =
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			if (message === CONST_ERRORS['401ExpiredToken']) {
				dispatch(logout())
			}
			dispatch({
				type: hrConstants.levelConstants.levelCreate.fail,
				payload: message,
			})
		}
	}

export const levelUpdate = (level) => async (dispatch, getState) => {
	try {
		// console.log(level)

		dispatch({ type: hrConstants.levelConstants.levelUpdate.request })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}
		// console.log(level)
		const { data } = await axios.put(
			CONST_API.channels.hr + CONST_API.routes.hr.levels + `/${level._id}`,
			level,
			config,
		)
		// console.log(data)
		dispatch({
			type: hrConstants.levelConstants.levelUpdate.success,
			payload: data,
		})
		dispatch({
			type: hrConstants.levelConstants.levelDetails.success,
			payload: data,
		})
	} catch (error) {
		const message =
			error.response && error.response.data.message
				? error.response.data.message
				: error.message
		if (message === CONST_ERRORS['401ExpiredToken']) {
			dispatch(logout())
		}
		dispatch({
			type: hrConstants.levelConstants.levelUpdate.fail,
			payload: message,
		})
	}
}

export const levelDelete = (level) => async (dispatch, getState) => {
	try {
		dispatch({ type: hrConstants.levelConstants.levelDelete.request })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		await axios.delete(
			CONST_API.channels.hr + CONST_API.routes.hr.levels + `/${level._id}`,
			config,
		)
		dispatch({ type: hrConstants.levelConstants.levelDelete.success })
	} catch (error) {
		const message =
			error.response && error.response.data.message
				? error.response.data.message
				: error.message
		if (message === CONST_ERRORS['401ExpiredToken']) {
			dispatch(logout())
		}
		dispatch({
			type: hrConstants.levelConstants.levelDelete.fail,
			payload: message,
		})
	}
}
