//phase-edit-row.js
import React, { useEffect, useState } from 'react'
import { ICONS_DEFAULT } from '../../../constants/icons'

const PhaseEditRow = (props) => {
	const [phaseName, setPhaseName] = useState('')
	const [group, setGroup] = useState('')
	const [department, setDepartment] = useState('')
	const [mappedPhase, setMappedPhase] = useState('')

	// Set State
	useEffect(() => {
		if (props) {
			setDepartment(props.phase.department)
			setPhaseName(props.phase.phase)
			setGroup(props.phase.group)
			setMappedPhase(props.phase.mappedPhase)
		} else {
			setDepartment('')
			setGroup('')
			setPhaseName('')
			setMappedPhase('')
		}
		return () => {}
	}, [props])

	// Handlers
	const changeDepartmentHandler = (e) => setDepartment(e.target.value)
	const changeGroupHandler = (e) => setGroup(e.target.value)
	const changePhaseNameHandler = (e) => setPhaseName(e.target.value)
	const changeMappedPhaseHandler = (e) => setMappedPhase(e.target.value)

	const cancelHandler = () => {
		props.onCancel()
	}

	const submitHandler = (e) => {
		e.preventDefault()
		props.onSave({ ...props.phase, department, group, mappedPhase, phaseName })
	}

	return (
		<form
			onSubmit={submitHandler}
			className='group col-span-5  text-xs items-center grid grid-cols-5 max-h-[400px] overflow-y-auto border-b-1 border-romanLightBorder1 dark:border-romanDarkBorder1'
		>
			<input
				type='text'
				name='department'
				value={department}
				onChange={changeDepartmentHandler}
				className='w-full px-6 py-4 focus:border-b-2 border-b-1 border-romanLightBorder1 dark:border-romanDarkBorder1 focus:border-romanRed2   focus:dark:border-romanGold1 bg-romanLight1  focus:outline-none   dark:text-romanDarkTextActive  dark:bg-romanDark4 placeholder:font-sans placeholder:font-light'
			/>
			<input
				type='text'
				name='group'
				value={group}
				onChange={changeGroupHandler}
				className='w-full px-6 py-4 focus:border-b-2 border-b-1 border-romanLightBorder1 dark:border-romanDarkBorder1 focus:border-romanRed2   focus:dark:border-romanGold1 bg-romanLight1  focus:outline-none   dark:text-romanDarkTextActive  dark:bg-romanDark4 placeholder:font-sans placeholder:font-light'
			/>
			<input
				type='text'
				name='mappedPhase'
				value={mappedPhase}
				onChange={changeMappedPhaseHandler}
				className='w-full px-6 py-4 focus:border-b-2 border-b-1 border-romanLightBorder1 dark:border-romanDarkBorder1 focus:border-romanRed2   focus:dark:border-romanGold1 bg-romanLight1  focus:outline-none   dark:text-romanDarkTextActive  dark:bg-romanDark4 placeholder:font-sans placeholder:font-light'
			/>
			<input
				type='text'
				name='phase'
				value={phaseName}
				onChange={changePhaseNameHandler}
				className='w-full px-6 py-4 focus:border-b-2 border-b-1 border-romanLightBorder1 dark:border-romanDarkBorder1 focus:border-romanRed2   focus:dark:border-romanGold1 bg-romanLight1  focus:outline-none   dark:text-romanDarkTextActive  dark:bg-romanDark4 placeholder:font-sans placeholder:font-light'
			/>

			<div className='group col-span-1  pl-8 py-2 overflow-hidden flex items-center justify-start space-x-3'>
				<button
					type='button'
					onClick={cancelHandler}
					className='text-sm text-center items-center h-8 w-8 rounded-full p-2 font-bold border-1 border-transparent hover:border-romanLightTextInactive text-romanRed2   dark:border-romanDarkTextInactive dark:text-romanRed2   hover:opacity-50'
				>
					{ICONS_DEFAULT.cancel}
				</button>
				<button
					type='submit'
					className='text-sm text-center items-center h-8 w-8 rounded-full p-2 font-bold border-1 border-transparent hover:border-romanLightTextInactive text-green-600 dark:border-romanDarkTextInactive dark:text-green-500 hover:opacity-50'
				>
					{ICONS_DEFAULT.checkMark}
				</button>
			</div>
		</form>
	)
}

export default PhaseEditRow
