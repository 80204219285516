//phase-list.js
import React, { useState, useRef, useEffect } from 'react'
import { ICONS_DEFAULT } from '../../../constants/icons'
import {
	ContextMenu,
	CenterModal,
	Button,
	DeleteWarning,
} from '../../../components'
import TableItem from '../components/table-item'
import PhaseAddEdit from './phase-add-edit'
import { CONST_ADD, CONST_DELETE, CONST_EDIT } from '../../../constants/general'
import {
	createPhase,
	deletePhase,
	updatePhase,
	getPhases,
} from '../../../redux/actions'
import { useStateContext } from '../../../contexts/ContextProvider'
import { useDispatch } from 'react-redux'
import PhaseEditRow from './phase-edit-row'

const headerFields = ['Department', 'Work Group', 'Mapped Phase', 'Phase', ' ']

const PhaseList = ({ phases }) => {
	const { handleClick, isClicked, lastEvent, lastPayload } = useStateContext()

	const dispatch = useDispatch()

	// Draggable

	const [sortedItems, setSortedItems] = useState([])

	const [mouseIdx, setMouseIdx] = useState()

	const [editLine, setEditLine] = useState(null)

	const [renderedList, setRenderedList] = useState([])
	const [dragging, setDragging] = useState(false)
	const dragItem = useRef()
	const dragNode = useRef()

	useEffect(() => {
		if (phases && phases.length > 0) {
			const arr = phases.sort((a, b) => a.order - b.order)
			setSortedItems(arr)
		}
		return () => {}
	}, [phases])

	useEffect(() => {
		sortedItems && sortedItems.length > 0 && setRenderedList(sortedItems)
		return () => {}
	}, [sortedItems])

	const handleDragStart = (e, params) => {
		dragItem.current = params
		dragNode.current = e.target
		setTimeout(() => {
			setDragging(true)
		}, 0)
	}

	const handleDragStop = (e) => {
		dragItem.current = null
		dragNode.current = null
		setDragging(false)
		renderedList.forEach((item, idx) => {
			dispatch(updatePhase({ ...item, order: parseInt(idx + 1) }))
		})
		setTimeout(() => {
			dispatch(getPhases())
		}, 1000)
	}

	const handleMouseEnter = (e, { idx }) => {
		e.preventDefault()

		setMouseIdx(idx)
	}

	const handleDragEnter = (e, params) => {
		e.preventDefault()
		const currentItem = dragItem.current

		if (e.target !== dragNode.current) {
			dragItem.current = params
			let newList = JSON.parse(JSON.stringify(renderedList))
			newList.splice(params.idx, 0, newList.splice(currentItem.idx, 1)[0])

			setRenderedList(newList)
			// dragItem.current = params
			// setRenderedList((oldList) => {
			// 	let newList = JSON.parse(JSON.stringify(oldList))
			// 	newList.splice(params.idx, 0, newList.splice(currentItem.idx, 1)[0])
			// 	return newList
			// })
		}
	}

	const deleteWarningHandler = (e, phase) => {
		setEditLine(null)
		handleClick('centerModal', e, {
			element: (
				<DeleteWarning
					message={'Are you sure you want to delete this phase?'}
					onClick={(e) => {
						e.preventDefault()

						dispatch(deletePhase(phase._id))
					}}
				/>
			),
			type: CONST_DELETE,
		})
	}

	const createPhaseHandler = (e, phase) => {
		e.preventDefault()
		dispatch(createPhase({ ...phase, order: phases.length + 1 }))
	}

	const addPhaseHandler = (e) => {
		setEditLine(null)
		handleClick('centerModal', e, {
			element: <PhaseAddEdit onSave={createPhaseHandler} type={CONST_ADD} />,
			type: CONST_ADD,
		})
	}

	const editLineHandler = (id) => {
		editLine !== id && setEditLine(id)
	}
	const cancelEditHandler = () => {
		setEditLine(null)
	}

	const updatePhaseHandler = (phase) => {
		dispatch(updatePhase(phase))
	}
	const saveHandler = (e) => {
		e.preventDefault()
	}

	return (
		<div className='grid grid-cols-5 max-w-6xl mx-auto '>
			{isClicked.centerModal && (
				<CenterModal
					injectedButton={
						<Button
							icon={ICONS_DEFAULT.cancel}
							color='rgb(153, 171, 180)'
							bgHoverColor='light-gray'
							size='lg'
							borderRadius='50%'
							noShadow={true}
							padding={'1 m-auto'}
						/>
					}
					width={'w-[400px]'}
					category={'Configuration'}
					title={
						lastPayload.type === CONST_EDIT
							? 'Edit Phase'
							: lastPayload.type === CONST_ADD
							? 'Add Phase'
							: 'Delete Phase'
					}
					headerClass='flex justify-between border-b-1 border-romanLightBorder1 dark:border-romanDarkBorder1'
					// disabled={false}
					categoryClass='px-2 text-sm text-romanLightTextInactive dark:text-romanDarkTextInactive'
					titleClass='px-2 text-md pb-1 font-extrabold tracking-tight dark:text-romanDarkTextActive text-romanLightTextActive '
				>
					{lastPayload.element}
				</CenterModal>
			)}
			{isClicked.contextMenu && (
				<ContextMenu
					injectedButton={
						<Button
							icon={ICONS_DEFAULT.cancel}
							color='rgb(153, 171, 180)'
							bgHoverColor='light-gray'
							size='lg'
							borderRadius='50%'
							noShadow={true}
							padding={'1 m-auto'}
						/>
					}
					headerClass='flex justify-between border-b-1 border-romanLightBorder1 dark:border-romanDarkBorder1'
					lastEvent={lastEvent}
					onSave={saveHandler}
					width={'w-[200px]'}
					category={'Configuration'}
					title={'Update Phase'}
					// disabled={false}
					categoryClass='text-xs text-romanLightTextInactive dark:text-romanDarkTextInactive'
					titleClass='text-sm font-extrabold tracking-tight dark:text-romanDarkTextActive text-romanLightTextActive'
					xOffset={-200}
				>
					{lastPayload}
				</ContextMenu>
			)}
			<div className='col-span-2 md:col-span-3 font-bold  mb-2'>Phase List</div>
			<div className='col-span-3 md:col-span-2 font-bold mx-auto mb-2'>
				<div
					onClick={addPhaseHandler}
					className='flex items-center justify-end mx-auto gap-2 hover:scale-110 bg-romanRed2   dark:bg-romanGold1 dark:text-romanDark4 text-romanLight1 rounded-lg px-4 py-2 font-bold'
				>
					<div className=' dark:text-romanDark4 text-romanLight1'>
						{ICONS_DEFAULT.plus}
					</div>
					<div className='text-sm'>Create New Phase</div>
				</div>
			</div>
			{headerFields.map((field, idx) => (
				<div key={idx} className='col-span-1 text-xs font-bold pl-4'>
					{field}
				</div>
			))}

			{sortedItems.length === 0 ? (
				<div className='col-span-5 text-center justify-center mt-4'>
					<div>Create your first phase!</div>
				</div>
			) : sortedItems[0] === 'Error loading phases...' ? (
				<div className='col-span-5 text-center justify-center mt-4'>
					<div>Error Loading Phases</div>
				</div>
			) : (
				<div className='col-span-5 max-h-96 overflow-scroll'>
					{sortedItems.map((phase, idx) =>
						editLine === phase._id ? (
							<PhaseEditRow
								key={phase._id}
								phase={phase}
								onCancel={cancelEditHandler}
								onSave={updatePhaseHandler}
							/>
						) : (
							<React.Fragment key={phase._id}>
								<TableItem
									onClick={() => editLineHandler(phase._id)}
									draggable={true}
									onMouseEnter={(e) => handleMouseEnter(e, { idx })}
									onDragStart={(e) => handleDragStart(e, { idx })}
									onDragEnter={
										dragging ? (e) => handleDragEnter(e, { idx }) : null
									}
									onDragEnd={(e) => handleDragStop(e)}
									className={`relative group col-span-1  pl-4 py-2 overflow-hidden`}
									wrapperClass={
										'group col-span-5  text-xs items-center grid grid-cols-5 max-h-[400px] overflow-y-auto border-b-1 border-romanLightBorder1 dark:border-romanDarkBorder1'
									}
									draggableClassname={
										' absolute text-md top-[9px] text-romanLightTextInactive dark:romanDarkTextInactive left-3 opacity-0 group-hover:opacity-100 '
									}
									fields={[
										phase.department,
										phase.group,
										phase.mappedPhase || ' ',
										phase.phase,

										<div
											className={
												'col-span-1  pl-4 overflow-hidden items-center justify-start flex space-x-2'
											}
										>
											<div
												onClick={() => editLineHandler(phase._id)}
												className='text-sm text-center items-center h-8 w-8 rounded-full p-2 font-bold border-1 border-transparent hover:border-romanLightTextInactive text-romanLightTextActive dark:border-romanDarkTextInactive dark:text-romanDarkTextInactive hover:opacity-50'
											>
												{ICONS_DEFAULT.edit}
											</div>
											<div
												onClick={(e) => deleteWarningHandler(e, phase)}
												className='text-sm text-center items-center h-8 w-8 rounded-full p-2 font-bold border-1 border-transparent hover:border-romanLightTextInactive text-romanLightTextActive dark:border-romanDarkTextInactive dark:text-romanDarkTextInactive hover:opacity-50'
											>
												{ICONS_DEFAULT.deleteTrash}
											</div>
										</div>,
									]}
								/>
							</React.Fragment>
						),
					)}
				</div>
			)}
		</div>
	)
}

export default PhaseList
