//kpi-badge.js
import React from 'react'
import { CONST_COLORS, GEN_CONST } from '../../../constants/general'

const KPIBadge = ({
	percentToGoal,
	goal,
	goalLabel,
	value,
	valueLabel,
	valueType,
	kpi,
	icon,
	tickerString,
	zeroPrice,
}) => {
	return (
		<div className='grid grid-cols-1 md:grid-cols-2 '>
			<div className='col-span-1 text-center text-white bg-romanGoldScorecard rounded-lg mt-8 z-10'>
				<div className='flex flex-row justify-center items-center  space-x-4 p-5'>
					<div className='flex flex-col text-center'>
						<div className=' text-3xl font-bold'>{`${percentToGoal}%`}</div>
						<div className=' text-xs'>{'of goal'}</div>
					</div>
					<div className='text-4xl text-romanRedScorecard'>{icon}</div>
					<div
						className={`w-2 h-10 rounded-full  `}
						style={{
							backgroundImage: `linear-gradient(to top, ${CONST_COLORS.romanRedScorecard} ${percentToGoal}%, white ${percentToGoal}%)`,
						}}
					/>
				</div>
				<div className=' text-romanRedScorecard font-bold'>
					{`GOAL = ${
						goalLabel
							? goalLabel
							: valueType === GEN_CONST.types.percent
							? `${goal * 100}%`
							: goal
					}`}
				</div>
			</div>
			<div className='col-span-1 text-black text-center bg-romanGray200Scorecard rounded-b-lg md:rounded-r-lg -mt-2 md:-ml-2  md:mt-8 z-8 pl-4'>
				<div className='pt-5 text-sm font-bold'>{kpi}</div>
				<div className='p-3 text-3xl font-bold'>
					{valueLabel
						? `${valueLabel.pre}${value}${valueLabel.post}`
						: valueType === GEN_CONST.types.percent
						? `${(value * 100).toFixed(0)}%`
						: value}
				</div>
				{zeroPrice && (
					<div className='overflow-hidden whitespace-nowrap'>
						<div className='text-center text-xs italic'>
							{`${zeroPrice} jobs without value.`}
						</div>
					</div>
				)}
				<div className='overflow-hidden whitespace-nowrap'>
					<div className='animate-scroll-left text-sm font-semibold'>
						{tickerString || ''}
					</div>
				</div>
			</div>
		</div>
	)
}

export default KPIBadge
