//collections-scorecard.js
import React from 'react'
import {
	PageWrapper,
	ScorecardHeader,
	ScorecardLinks,
} from '../../../components'

const AdminScorecard = () => {
	return (
		<PageWrapper title='Scorecards' links={<ScorecardLinks />}>
			<ScorecardHeader title='COLLECTIONS SCOREBOARD' />
		</PageWrapper>
	)
}

export default AdminScorecard
