const { eventConstants } = require('./eventConstants')
const { jobConstants } = require('./jobConstants')
const { phaseConstants } = require('./phaseConstants')
const { estimateConstants } = require('./estimateConstants')

exports.giddyUp = {
	eventConstants,
	jobConstants,
	phaseConstants,
	estimateConstants,
}
