import React from 'react'
import { PageWrapper, ScorecardHeader, ScorecardLinks } from '../../components'

const DepartmentScorecards = () => {
	return (
		<PageWrapper title='Scorecards' links={<ScorecardLinks />}>
			<ScorecardHeader title='Summary coming soon...' />
		</PageWrapper>
	)
}

export default DepartmentScorecards
