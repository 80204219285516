//job-list.js
import React, { useEffect } from 'react'

const JobList = ({ jobs }) => {
	useEffect(() => {
		console.log(jobs)
	}, [jobs])

	return <div className='max-w-6xl mx-auto'>JobList</div>
}

export default JobList
