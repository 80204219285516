// department-list.js
import React, { useState, useRef, useEffect } from 'react'
import { ICONS_DEFAULT } from '../../../constants/icons'
import {
	ContextMenu,
	CenterModal,
	Button,
	MenuPayload,
	DeleteWarning,
} from '../../../components'
import TableItem from '../components/table-item'
import DepartmentAddEdit from './department-add-edit'
import { CONST_ADD, CONST_DELETE, CONST_EDIT } from '../../../constants/general'
import {
	createDepartment,
	deleteDepartment,
	updateDepartment,
	getDepartments,
} from '../../../redux/actions'
import { useStateContext } from '../../../contexts/ContextProvider'
import { useDispatch } from 'react-redux'

const headerFields = ['Department', ' ']

const DepartmentList = ({ departments }) => {
	const { handleClick, isClicked, lastEvent, lastPayload } = useStateContext()

	const dispatch = useDispatch()
	// Draggable
	const [sortedItems, setSortedItems] = useState([])
	const [mouseIdx, setMouseIdx] = useState()

	const [renderedList, setRenderedList] = useState([])
	const [dragging, setDragging] = useState(false)
	const dragItem = useRef()
	const dragNode = useRef()

	useEffect(() => {
		if (departments && departments.length > 0) {
			const arr = departments.sort((a, b) => a.order - b.order)
			setSortedItems(arr)
		}
		return () => {}
	}, [departments])

	useEffect(() => {
		sortedItems && sortedItems.length > 0 && setRenderedList(sortedItems)
		return () => {}
	}, [sortedItems])

	const handleDragStart = (e, params) => {
		dragItem.current = params
		dragNode.current = e.target
		setTimeout(() => {
			setDragging(true)
		}, 0)
	}

	const handleDragStop = (e) => {
		dragItem.current = null
		dragNode.current = null
		setDragging(false)
		renderedList.forEach((item, idx) => {
			dispatch(updateDepartment({ ...item, order: parseInt(idx + 1) }))
		})
		setTimeout(() => {
			dispatch(getDepartments())
		}, 1000)
	}

	const handleMouseEnter = (e, { idx }) => {
		e.preventDefault()

		setMouseIdx(idx)
	}

	const handleDragEnter = (e, params) => {
		e.preventDefault()
		const currentItem = dragItem.current

		if (e.target !== dragNode.current) {
			dragItem.current = params
			let newList = JSON.parse(JSON.stringify(renderedList))
			newList.splice(params.idx, 0, newList.splice(currentItem.idx, 1)[0])

			setRenderedList(newList)
			// dragItem.current = params
			// setRenderedList((oldList) => {
			// 	let newList = JSON.parse(JSON.stringify(oldList))
			// 	newList.splice(params.idx, 0, newList.splice(currentItem.idx, 1)[0])
			// 	return newList
			// })
		}
	}
	const handler = (e, element) => handleClick('contextMenu', e, element)
	const centerModalActionHandler = (e, department, type) => {
		e.preventDefault()

		if (type === CONST_ADD) {
			dispatch(
				createDepartment({ ...department, order: departments.length + 1 }),
			)
		} else if (type === CONST_EDIT) {
			dispatch(updateDepartment(department))
		}
	}

	const contextMenuActionHandler = (e, payload, department) => {
		e.preventDefault()
		switch (payload) {
			case CONST_ADD:
				handleClick('centerModal', e, {
					element: (
						<DepartmentAddEdit
							onSave={centerModalActionHandler}
							type={CONST_ADD}
						/>
					),
					type: CONST_ADD,
				})

				break
			case CONST_EDIT:
				handleClick('centerModal', e, {
					element: (
						<DepartmentAddEdit
							onSave={centerModalActionHandler}
							type={CONST_EDIT}
							department={department}
						/>
					),
					type: CONST_EDIT,
				})

				break
			case CONST_DELETE:
				handleClick('centerModal', e, {
					element: (
						<DeleteWarning
							message={'Are you sure you want to delete this department?'}
							onClick={(e) => {
								e.preventDefault()

								dispatch(deleteDepartment(department._id))
							}}
						/>
					),
					type: CONST_DELETE,
				})

				break

			default:
				break
		}
	}
	const saveHandler = (e) => {
		e.preventDefault()
	}
	return (
		<div className='grid grid-cols-2 max-w-6xl mx-auto '>
			{isClicked.centerModal && (
				<CenterModal
					injectedButton={
						<Button
							icon={ICONS_DEFAULT.cancel}
							color='rgb(153, 171, 180)'
							bgHoverColor='light-gray'
							size='lg'
							borderRadius='50%'
							noShadow={true}
							padding={'1 m-auto'}
						/>
					}
					width={'w-[400px]'}
					category={'Configuration'}
					title={
						lastPayload.type === CONST_EDIT
							? 'Edit Department'
							: lastPayload.type === CONST_ADD
							? 'Add Department'
							: 'Delete Department'
					}
					headerClass='flex justify-between border-b-1 border-romanLightBorder1 dark:border-romanDarkBorder1'
					// disabled={false}
					categoryClass='px-2 text-sm text-romanLightTextInactive dark:text-romanDarkTextInactive'
					titleClass='px-2 text-md pb-1 font-extrabold tracking-tight dark:text-romanDarkTextActive text-romanLightTextActive '
				>
					{lastPayload.element}
				</CenterModal>
			)}
			{isClicked.contextMenu && (
				<ContextMenu
					injectedButton={
						<Button
							icon={ICONS_DEFAULT.cancel}
							color='rgb(153, 171, 180)'
							bgHoverColor='light-gray'
							size='lg'
							borderRadius='50%'
							noShadow={true}
							padding={'1 m-auto'}
						/>
					}
					headerClass='flex justify-between border-b-1 border-romanLightBorder1 dark:border-romanDarkBorder1'
					lastEvent={lastEvent}
					onSave={saveHandler}
					width={'w-[200px]'}
					category={'Configuration'}
					title={'Update Department'}
					// disabled={false}
					categoryClass='text-xs text-romanLightTextInactive dark:text-romanDarkTextInactive'
					titleClass='text-sm font-extrabold tracking-tight dark:text-romanDarkTextActive text-romanLightTextActive'
					xOffset={-200}
				>
					{lastPayload}
				</ContextMenu>
			)}
			<div className='col-span-1 font-bold  mb-2'>Department List</div>
			<div className='col-span-1 font-bold mx-auto mb-2'>
				<div
					onClick={(e) => contextMenuActionHandler(e, CONST_ADD)}
					className='flex items-center justify-end mx-auto gap-2 hover:scale-110 bg-romanRed2   dark:bg-romanGold1 dark:text-romanDark4 text-romanLight1 rounded-lg px-4 py-2 font-bold'
				>
					<div className=' dark:text-romanDark4 text-romanLight1'>
						{ICONS_DEFAULT.plus}
					</div>
					<div className='text-sm'>Create New Department</div>
				</div>
			</div>
			{headerFields.map((field, idx) => (
				<div key={idx} className='col-span-1 text-xs font-bold pl-4'>
					{field}
				</div>
			))}

			{sortedItems.length === 0 ? (
				<div className='col-span-2 text-center justify-center mt-4'>
					<div>Create your first department!</div>
				</div>
			) : sortedItems[0] === 'Error loading departments...' ? (
				<div className='col-span-2 text-center justify-center mt-4'>
					<div>Error Loading Departments</div>
				</div>
			) : (
				<div className='col-span-2 max-h-96 overflow-scroll'>
					{sortedItems.map((department, idx) => (
						<TableItem
							key={idx}
							className={`group col-span-1  pl-4 py-2`}
							wrapperClass={
								'group col-span-2 text-xs items-center grid grid-cols-2'
							}
							fields={[
								department.department,
								department.group,
								<div
									className={
										'col-span-1 pl-4 overflow-hidden items-center justify-center'
									}
									onClick={(e) =>
										handler(
											e,
											<MenuPayload
												item={department}
												onAction={contextMenuActionHandler}
											/>,
										)
									}
								>
									<div className='group-hover:visible invisible'>
										{ICONS_DEFAULT.tripleDot}
									</div>
								</div>,
							]}
						/>
					))}
				</div>
			)}
		</div>
	)
}

export default DepartmentList
