// users.js
import React, { useEffect, useState } from 'react'
import UserList from './user-list'
import UserSummary from './user-summary'
import { useDispatch, useSelector } from 'react-redux'
import { getUsers } from '../../../redux/actions'
import Sidebar from '../../../components/Navigation/sidebar2'
import { AdminLinks, Header2, HomePageLinks } from '../../../components'

const Users = () => {
	const dispatch = useDispatch()
	const usersRdx = useSelector((state) => state.users)

	const [users, setUsers] = useState([])

	useEffect(() => {
		dispatch(getUsers())

		return () => {}
	}, [dispatch])

	useEffect(() => {
		if (usersRdx && usersRdx.data) {
			setUsers(usersRdx.data)
		}

		if (usersRdx && usersRdx.error) {
			setUsers(['Error loading users...'])
		}

		return () => {}
	}, [usersRdx.data, usersRdx.error, usersRdx])

	return (
		<div className='dark:bg-main-dark-bg bg-main-bg dark:text-romanLight1 font-opensans mt-20'>
			<Sidebar title={'Admin Settings'} links={<AdminLinks />} />
			<Header2 links={<HomePageLinks />} />
			<div className='ml-4'>
				<UserSummary users={users} />

				<UserList users={users} />
			</div>
		</div>
	)
}

export default Users
