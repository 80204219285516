//phase-add-edit.js
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Loader } from '../../../components'

import { useStateContext } from '../../../contexts/ContextProvider'
import { CONST_ADD } from '../../../constants/general'
import { getPhases } from '../../../redux/actions'
import { resetPhaseItem } from '../../../redux/actions/giddyUpActions/phaseActions'

const PhaseAddEdit = ({ onSave, type, phase }) => {
	const { handleClick, initialState } = useStateContext()

	const dispatch = useDispatch()

	const createPhaseRdx = useSelector((state) => state.createPhase)
	const { loading: createLoading, data: createSuccess } = createPhaseRdx
	const updatePhaseRdx = useSelector((state) => state.updatePhase)
	const { loading: updateLoading, data: updateSuccess } = updatePhaseRdx

	const [loading, setLoading] = useState(false)

	const [phaseName, setPhaseName] = useState('')
	const [group, setGroup] = useState('')
	const [department, setDepartment] = useState('')
	const [mappedPhase, setMappedPhase] = useState('')

	const changeDepartmentHandler = (e) => setDepartment(e.target.value)
	const changeGroupHandler = (e) => setGroup(e.target.value)
	const changePhaseNameHandler = (e) => setPhaseName(e.target.value)
	const changeMappedPhaseHandler = (e) => setMappedPhase(e.target.value)

	useEffect(() => {
		if (createLoading || updateLoading) {
			setLoading(true)
		} else {
			setLoading(false)
		}

		return () => {}
	}, [createLoading, updateLoading])

	useEffect(() => {
		if (createSuccess || updateSuccess) {
			handleClick({ ...initialState })
			createSuccess && dispatch(resetPhaseItem('createPhase'))
			updateSuccess && dispatch(resetPhaseItem('updatePhase'))
			dispatch(getPhases())
		}

		return () => {}
	}, [createSuccess, updateSuccess])

	useEffect(() => {
		if (phase !== undefined) {
			setDepartment(phase.department)
			setPhaseName(phase.phase)
			setGroup(phase.group)
			setMappedPhase(phase.mappedPhase)
		} else {
			setDepartment('')
			setGroup('')
			setPhaseName('')
			setMappedPhase('')
		}
		return () => {}
	}, [type, phase])

	return (
		<form
			autoComplete='off'
			onSubmit={(e) =>
				onSave(
					e,
					{ ...phase, phase: phaseName, department, group, mappedPhase },
					type,
				)
			}
		>
			<div className='flex flex-col space-y-4 my-4 mx-6'>
				<input
					type='text'
					name='phase'
					value={phaseName}
					onChange={changePhaseNameHandler}
					placeholder='Enter phase name'
					// className=' pl-3 border-0 text-gray-600 text-sm  py-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-yellow-500'
					className='w-full px-6 py-4 focus:border-b-2 border-b-1 border-romanLightBorder1 dark:border-romanDarkBorder1 focus:border-romanRed2   focus:dark:border-romanGold1 bg-romanLight1  focus:outline-none   dark:text-romanDarkTextActive  dark:bg-romanDark4 placeholder:font-sans placeholder:font-light'
				/>
				<input
					type='text'
					name='mappedPhase'
					value={mappedPhase}
					onChange={changeMappedPhaseHandler}
					placeholder='Enter mapped phase name'
					// className=' pl-3 border-0 text-gray-600 text-sm  py-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-yellow-500'
					className='w-full px-6 py-4 focus:border-b-2 border-b-1 border-romanLightBorder1 dark:border-romanDarkBorder1 focus:border-romanRed2   focus:dark:border-romanGold1 bg-romanLight1  focus:outline-none   dark:text-romanDarkTextActive  dark:bg-romanDark4 placeholder:font-sans placeholder:font-light'
				/>
				<input
					type='text'
					name='department'
					value={department}
					onChange={changeDepartmentHandler}
					placeholder='Enter department name'
					// className=' pl-3 border-0 text-gray-600 text-sm  py-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-yellow-500'
					className='w-full px-6 py-4 focus:border-b-2 border-b-1 border-romanLightBorder1 dark:border-romanDarkBorder1 focus:border-romanRed2   focus:dark:border-romanGold1 bg-romanLight1  focus:outline-none   dark:text-romanDarkTextActive  dark:bg-romanDark4 placeholder:font-sans placeholder:font-light'
				/>

				<input
					type='text'
					name='group'
					value={group}
					onChange={changeGroupHandler}
					placeholder='Enter group name'
					// className=' pl-3 border-0 text-gray-600 text-sm  py-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-yellow-500'
					className='w-full px-6 py-4 focus:border-b-2 border-b-1 border-romanLightBorder1 dark:border-romanDarkBorder1 focus:border-romanRed2   focus:dark:border-romanGold1 bg-romanLight1  focus:outline-none   dark:text-romanDarkTextActive  dark:bg-romanDark4 placeholder:font-sans placeholder:font-light'
				/>
			</div>
			<footer className='flex justify-end p-3 mt-4 border-t-1 border-romanLightBorder1 dark:border-romanDarkBorder1'>
				<button
					type='submit'
					className={` px-6 py-2 w-36 rounded bg-romanRed2   text-romanLight2 dark:bg-romanGold1 dark:text-romanDark3 `}
				>
					{loading ? <Loader className={' mx-auto '} /> : `Save`}
				</button>
			</footer>
		</form>
	)
}

PhaseAddEdit.defaultProps = {
	type: CONST_ADD,
}

export default PhaseAddEdit
