//pulse-report.js
import React from 'react'
import { PulseReportLinks, PageWrapper } from '../../components'

const PulseReport = () => {
	return (
		<PageWrapper links={<PulseReportLinks />}>
			<div className='dark:text-white text-xl md:text-3xl text-center justify-center items-center p-4'>
				Pulse Report Summary Dashboard
			</div>
		</PageWrapper>
	)
}

export default PulseReport
