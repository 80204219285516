//production-scorecard.js
import React, { useEffect, useState } from 'react'
import io from 'socket.io-client'
import {
	KPIBadge,
	KPILineChart,
	KPIBarChart,
	PageWrapper,
	ScorecardLinks,
	ScorecardHeader,
	KPIMenu,
} from '../../../components'
import {
	CONST_ADD,
	CONST_COLORS,
	CONST_DELETE,
	GEN_CONST,
} from '../../../constants/general'
import { ICONS, ICONS_DEFAULT } from '../../../constants/icons'
import { useDispatch, useSelector } from 'react-redux'
import { getScorecardData } from '../../../redux/actions/'
import JobReport from '../../../components/page-components/scorecards/production/job-report'

const calcPercent = (e) => Math.floor((e.value / e.target) * 100)
// Filtering the CONST_ROMAN_KPI to include only those objects where department is 'Marketing'
const eventTypes = [
	'permitApplied',
	'permitReceived',
	'repairScheduled',
	'repairComplete',
	'tearOffScheduled',
	'jobScheduled',
	'installScheduled',
	'jobCompleted',
	'finalWalkthroughApproved',
]
const returnedEventTypes = [
	{
		kpi: 'permitApplied',
		label: 'Permits Applied For',
		icon: ICONS.data.fileExport,
		group: 'Permitting',
	},
	{
		kpi: 'permitReceived',
		label: 'Permits Received',
		icon: ICONS.data.fileCheck,
		group: 'Permitting',
	},
	{
		kpi: 'repairScheduled',
		label: 'Repairs Scheduled',
		icon: ICONS.data.calendarCheckFill,
		group: 'Repairs',
	},
	{
		kpi: 'repairComplete',
		label: 'Repairs Completed',
		icon: ICONS.construction.homeCheck,
		group: 'Repairs',
	},
	{
		kpi: 'tearOffScheduled',
		label: 'Tear Offs Scheduled',
		icon: ICONS.data.calendarCheckFill,
		group: 'Reroof',
	},
	{
		kpi: 'installScheduled',
		label: 'Installs Scheduled',
		icon: ICONS.data.calendarCheckFill,
		group: 'Reroof',
	},
	{
		kpi: 'jobCompleted',
		label: 'Jobs Completed',
		icon: ICONS.construction.homeCheck,
		group: 'Reroof',
	},
	{
		kpi: 'finalWalkthroughApproved',
		label: 'Walkthroughs Completed',
		icon: ICONS.default.handshake,
		group: 'Reroof',
	},
]

const updatedKpiLineCharts = [
	{
		kpi: `PERMITTING`,
		icon: ICONS.data.fileCheck,
		dataKey: 'permitApplied',
		dataKeyB: 'permitReceived',
		xDataKey: 'day',
		// data: permittingData,
		group: 'Permitting',
		strokeA: CONST_COLORS.romanGoldScorecard,
		strokeB: CONST_COLORS.romanRedScorecard,
		strokeWidthA: 2,
		strokeWidthB: 2,

		fillA: CONST_COLORS.romanGoldScorecard,
		fillB: CONST_COLORS.romanRedScorecard,
	},
	{
		kpi: `REPAIRS`,
		icon: ICONS.construction.wrenchHammer,
		dataKey: 'repairScheduled',
		dataKeyB: 'repairComplete',
		xDataKey: 'day',
		// data: repairsData,
		group: 'Repairs',
		strokeA: CONST_COLORS.romanGoldScorecard,
		strokeB: CONST_COLORS.romanRedScorecard,
		strokeWidthA: 2,
		strokeWidthB: 2,

		fillA: CONST_COLORS.romanGoldScorecard,
		fillB: CONST_COLORS.romanRedScorecard,
	},
	{
		kpi: `REROOF STARTS`,
		icon: ICONS.data.calendarCheck,
		dataKey: 'tearOffScheduled',
		dataKeyB: 'installScheduled',
		xDataKey: 'day',
		// data: reroofStartsData,
		group: 'Reroof',
		strokeA: CONST_COLORS.romanGoldScorecard,
		strokeB: CONST_COLORS.romanRedScorecard,
		strokeWidthA: 2,
		strokeWidthB: 2,

		fillA: CONST_COLORS.romanGoldScorecard,
		fillB: CONST_COLORS.romanRedScorecard,
	},
	{
		kpi: `REROOF COMPLETE`,
		icon: ICONS.default.handshake,
		dataKey: 'jobCompleted',
		dataKeyB: 'finalWalkthroughApproved',
		xDataKey: 'day',
		// data: reroofCompletionsData,
		group: 'Reroof',
		strokeA: CONST_COLORS.romanGoldScorecard,
		strokeB: CONST_COLORS.romanRedScorecard,
		strokeWidthA: 2,
		strokeWidthB: 2,

		fillA: CONST_COLORS.romanGoldScorecard,
		fillB: CONST_COLORS.romanRedScorecard,
	},
]
const updatedKpiBarCharts = [
	{
		kpi: `PERMITTING`,
		icon: ICONS.data.fileCheck,
		dataKey: 'permitApplied',
		dataKeyB: 'permitReceived',
		xDataKey: 'eventContact',
		// data: permittingData,
		group: 'Permitting',
		tickFormatter: true,
	},
	{
		kpi: `REPAIRS`,
		icon: ICONS.construction.wrenchHammer,
		dataKey: 'repairScheduled',
		dataKeyB: 'repairComplete',
		xDataKey: 'eventContact',
		// data: repairsData,
		group: 'Repairs',
		tickFormatter: true,
	},
	{
		kpi: `REROOF STARTS`,
		icon: ICONS.data.calendarCheck,
		dataKey: 'tearOffScheduled',
		dataKeyB: 'installScheduled',
		xDataKey: 'eventContact',
		// data: reroofStartsData,
		group: 'Reroof',
		tickFormatter: true,
	},
	{
		kpi: `REROOF COMPLETE`,
		icon: ICONS.default.handshake,
		dataKey: 'jobCompleted',
		dataKeyB: 'finalWalkthroughApproved',
		xDataKey: 'eventContact',
		// data: reroofCompletionsData,
		group: 'Reroof',
		tickFormatter: true,
	},
]

const phaseChanges = []

const getDayAbbreviation = (date) => {
	const dayNames = ['S', 'M', 'T', 'W', 'TH', 'F', 'SA']
	return dayNames[date.getDay()]
}

function processBarChartData(data) {
	const groupCounts = updatedKpiBarCharts.reduce((acc, chart) => {
		acc[chart.group] = {}
		return acc
	}, {})

	// Process each event
	data.forEach((event) => {
		const group = updatedKpiBarCharts.find(
			(chart) =>
				chart.dataKey === event.eventType || chart.dataKeyB === event.eventType,
		)?.group

		if (group) {
			const eventContact = event.eventContact

			// Initialize counts for the contact within the group if not already done
			if (!groupCounts[group][eventContact]) {
				groupCounts[group][eventContact] = {
					eventContact: eventContact,
					[event.eventType]: 1,
				}
			} else {
				groupCounts[group][eventContact][event.eventType] =
					(groupCounts[group][eventContact][event.eventType] || 0) + 1
			}
		}
	})

	// Transform the counts into the format expected by the bar chart component
	// and sort the data within each group
	const barChartsData = updatedKpiBarCharts.map((chart) => {
		const sortedData = Object.values(groupCounts[chart.group] || {}).sort(
			(a, b) => {
				return b[chart.dataKey] - a[chart.dataKey]
			},
		)

		return {
			...chart,
			data: sortedData,
		}
	})

	return barChartsData
}
function processLineChartData(data) {
	// Initialize chart data for each line chart
	const lineChartsData = updatedKpiLineCharts.map((chart) => {
		// Initialize data for both data keys
		const chartData = ['S', 'M', 'T', 'W', 'TH', 'F', 'SA'].map((day) => ({
			day,
			[chart.dataKey]: 0,
			[chart.dataKeyB]: 0,
		}))

		return {
			...chart,
			data: chartData,
		}
	})

	// Process each event and update the corresponding chart data
	data.forEach((event) => {
		const eventDate = new Date(event.timeStamp)
		const dayAbbrev = getDayAbbreviation(eventDate)

		lineChartsData.forEach((chart) => {
			if ([chart.dataKey, chart.dataKeyB].includes(event.eventType)) {
				const dayIndex = chart.data.findIndex((day) => day.day === dayAbbrev)
				if (dayIndex !== -1) {
					chart.data[dayIndex][event.eventType]++
				}
			}
		})
	})

	return lineChartsData
}

function processKpiData(data) {
	return returnedEventTypes.map((eventType) => {
		const count = data.filter(
			(event) => event.eventType === eventType.kpi,
		).length
		return {
			kpi: eventType.label,
			type: GEN_CONST.types.number,
			value: count,
			target: 45,
			icon: eventType.icon,
			percentToGoal: calcPercent({ value: count, target: 45 }),
			group: eventType.group, // Include group information
		}
	})
}

function processKpiGroups(data) {
	const processedKpis = processKpiData(data)
	const kpiBarCharts = processBarChartData(data)
	const kpiLineCharts = processLineChartData(data)

	// Initialize all groups
	const kpiGroups = {}
	const allGroups = new Set([
		...processedKpis.map((kpi) => kpi.group),
		...kpiBarCharts.map((chart) => chart.group),
		...kpiLineCharts.map((chart) => chart.group),
	])

	allGroups.forEach((group) => {
		kpiGroups[group] = {
			name: group,
			kpis: [],
			kpiLineCharts: [],
			kpiBarCharts: [],
		}
	})

	// Assign KPIs, line charts, and bar charts to their respective groups
	processedKpis.forEach((kpi) => {
		kpiGroups[kpi.group].kpis.push(kpi)
	})

	kpiBarCharts.forEach((chart) => {
		kpiGroups[chart.group].kpiBarCharts.push(chart)
	})

	kpiLineCharts.forEach((chart) => {
		kpiGroups[chart.group].kpiLineCharts.push(chart)
	})

	return Object.values(kpiGroups)
}

const ProductionScorecard = () => {
	const dispatch = useDispatch()
	const scoreCardDataRdx = useSelector((state) => state.scorecardData)

	const [activeChartType, setActiveChartType] = useState('line')
	const [switchInterval, setSwitchInterval] = useState(15)
	const [play, setPlay] = useState(true)
	const [rotate, setRotate] = useState(true)
	const [weekOffset, setWeekOffset] = useState(0)
	const [rotationIndex, setRotationIndex] = useState(0)

	const [kpiData, setKpiData] = useState({
		kpiGroups: [], // Each group will now contain its own line and bar charts
	})

	const weekOffsetHandler = (direction) => {
		setWeekOffset((currentOffset) => {
			if (direction === CONST_ADD && currentOffset < 0) {
				return currentOffset + 1
			} else if (direction === CONST_DELETE && currentOffset > -12) {
				return currentOffset - 1
			}
			return currentOffset
		})
	}
	useEffect(() => {
		let intervalId

		if (play) {
			intervalId = setInterval(() => {
				setActiveChartType((prevType) => (prevType === 'line' ? 'bar' : 'line'))
			}, switchInterval * 1000) // Switch chart type based on switchInterval
		}

		return () => {
			if (intervalId) {
				clearInterval(intervalId) // Clear interval on component unmount or if play is set to false
			}
		}
	}, [switchInterval, play]) // Depend on switchInterval and play

	const togglePlayHandler = () => {
		setPlay((prevPlay) => !prevPlay)
	}
	const toggleRotateHandler = () => {
		setRotate((prevRotate) => !prevRotate)
	}

	const switchIntervalHandler = (type) => {
		setSwitchInterval((prevInterval) => {
			if (type === CONST_ADD && prevInterval < 60) {
				return prevInterval + 1
			} else if (type !== CONST_ADD && prevInterval > 1) {
				return prevInterval - 1
			}
			return prevInterval // Return the current value if no change is needed
		})
	}

	useEffect(() => {
		// console.log(eventTypes)
		dispatch(
			getScorecardData({
				phaseChanges,
				eventTypes,
				key: 'production',
				weekOffset,
			}),
		)
	}, [dispatch, weekOffset])

	useEffect(() => {
		if (scoreCardDataRdx.production) {
			const newKpiGroups = processKpiGroups(scoreCardDataRdx.production)

			setKpiData({
				kpiGroups: newKpiGroups,
			})
		}
	}, [scoreCardDataRdx])
	useEffect(() => {
		// io('http://localhost:5000')
		// Connect to the Socket.IO server
		// Replace 'http://localhost:5000' with your server's URL
		const socket = io('https://www.nvoqe.com', { transports: ['websocket'] })
		// Setup event listeners

		socket.on('EVENTS', (data) => {
			if (data.action === 'EVENT_CREATED' && weekOffset === 0) {
				const event = data.payload
				if (
					event.eventType === 'permitApplied' ||
					event.eventType === 'permitReceived' ||
					event.eventType === 'repairScheduled' ||
					event.eventType === 'repairComplete' ||
					event.eventType === 'tearOffScheduled' ||
					event.eventType === 'jobScheduled' ||
					event.eventType === 'installScheduled' ||
					event.eventType === 'jobCompleted' ||
					event.eventType === 'finalWalkthroughApproved'
				) {
					// console.log('Sending marketing dispatch...')
					// console.log(eventTypes)
					dispatch(
						getScorecardData({
							phaseChanges,
							eventTypes,
							key: 'production',
						}),
					)
				}
			}
		})
		// Clean up the effect
		return () => socket.disconnect()
	}, [])

	useEffect(() => {
		let rotationInterval

		if (rotate) {
			rotationInterval = setInterval(() => {
				setRotationIndex((prevIndex) => (prevIndex + 1) % 3) // Assuming there are 3 groups
			}, switchInterval * 2000) // 2 x switch interval
		}

		return () => {
			if (rotationInterval) {
				clearInterval(rotationInterval)
			}
		}
	}, [switchInterval, rotate])

	return (
		<PageWrapper title='Scorecards' links={<ScorecardLinks />}>
			<ScorecardHeader title='PRODUCTION SCOREBOARD' />
			<KPIMenu
				weekOffset={weekOffset}
				onSetWeekOffset={weekOffsetHandler}
				play={play}
				onSwitchIntervalHandler={switchIntervalHandler}
				onTogglePlayHandler={togglePlayHandler}
				switchInterval={switchInterval}
				buttons={
					<div className='flex space-x-4'>
						<button
							className={`px-4 py-2 ${
								activeChartType === 'line'
									? 'bg-romanRedScorecard text-white '
									: 'bg-romanGoldScorecard text-black'
							}  rounded hover:bg-opacity-80`}
							onClick={() => setActiveChartType('line')}
						>
							Line Chart
						</button>
						<button
							className={`px-4 py-2 ${
								activeChartType === 'bar'
									? 'bg-romanRedScorecard text-white '
									: 'bg-romanGoldScorecard text-black'
							}  rounded hover:bg-opacity-80`}
							onClick={() => setActiveChartType('bar')}
						>
							Bar Chart
						</button>
					</div>
				}
			/>

			<div className='grid grid-cols-1 lg:grid-cols-10 gap-6  py-2 px-6 lg:px-0 text-justify text-romanDarkTextInactive dark:text-romanDarkTextActive'>
				{kpiData.kpiGroups
					.slice(rotationIndex)
					.concat(kpiData.kpiGroups.slice(0, rotationIndex))
					.map((group, groupIdx) => (
						<React.Fragment key={groupIdx}>
							{groupIdx === 0 ? (
								<div className='col-span-1 lg:col-span-10 pt-6 font-semibold text-2xl flex justify-start items-center  space-x-3 '>
									<div className='text-lg' onClick={toggleRotateHandler}>
										{rotate ? ICONS_DEFAULT.pause : ICONS_DEFAULT.play}
									</div>
									<div>{group.name}</div>
								</div>
							) : (
								<div className='col-span-1 lg:col-span-10 pt-6 font-semibold text-2xl '>
									{group.name}
								</div>
							)}
							<div className=' col-span-1 lg:col-span-4 flex flex-col h-full justify-between'>
								{group.kpis.map((kpi, kpiIdx) => (
									<KPIBadge
										key={kpiIdx}
										percentToGoal={kpi.percentToGoal}
										icon={kpi.icon}
										kpi={kpi.kpi}
										valueType={kpi.type}
										goal={kpi.target}
										value={kpi.value}
									/>
								))}
							</div>
							<div className=' col-span-1 lg:col-span-6'>
								{activeChartType === 'line' &&
									group.kpiLineCharts.map((e, idx) => (
										<KPILineChart
											dataKeyX={e.xDataKey}
											key={idx}
											kpi={e.kpi}
											icon={e.icon}
											dataKeyLine={e.dataKey}
											dataKeyLineB={e.dataKeyB}
											data={e.data}
											height={265}
											strokeA={e.strokeA}
											strokeB={e.strokeB}
											fillA={e.fillA}
											fillB={e.fillB}
											strokeDasharrayA={e.strokeDashArrayA}
											strokeDasharrayB={e.strokeDashArrayB}
											strokeWidthA={e.strokeWidthA}
											strokeWidthB={e.strokeWidthB}
										/>
									))}
								{activeChartType === 'bar' &&
									group.kpiBarCharts.map((e, idx) => (
										<KPIBarChart
											dataKeyX={e.xDataKey}
											key={idx}
											kpi={e.kpi}
											icon={e.icon}
											dataKeyBar={e.dataKey}
											dataKeyBarB={e.dataKeyB}
											data={e.data}
											height={265}
											tickFormatter={e.tickFormatter}
										/>
									))}
							</div>
						</React.Fragment>
					))}
			</div>

			<JobReport />
		</PageWrapper>
	)
}

export default ProductionScorecard
