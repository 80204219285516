//marketing-content.js
import React, { useEffect } from 'react'

import SummaryCard from './components/top-left'
import ShortTrend from './components/top-right'
import LongTrend from './components/bottom'
import { useDispatch, useSelector } from 'react-redux'
import { CONST_DATES, CONST_ROMAN_KPI } from '../../../constants/general'
import { getLeads } from '../../../redux/actions'
import { PulseReportLinks, PageWrapper } from '../../../components'

// Filtering the CONST_ROMAN_KPI to include only those objects where department is 'Marketing'
const eventTypes = Object.values(CONST_ROMAN_KPI)
	.filter((kpi) => kpi.department?.toLowerCase() === 'marketing')
	.map((kpi) => kpi.eventType)

const MarketingContent = () => {
	const dispatch = useDispatch()
	const leadsRdx = useSelector((state) => state.leads)

	useEffect(() => {
		dispatch(
			getLeads({
				eventTypes,
				timeRange: CONST_DATES.last7Days,
			}),
		)
		dispatch(
			getLeads({
				eventTypes,
				timeRange: CONST_DATES.last30Days,
			}),
		)
		dispatch(
			getLeads({
				eventTypes,
				timeRange: CONST_DATES.last90Days,
			}),
		)

		return () => {}
	}, [dispatch])

	const getLeadsHandler = (timeRange) => {
		console.log(timeRange)
		dispatch(getLeads({ eventTypes, timeRange }))
	}

	return (
		<PageWrapper links={<PulseReportLinks />}>
			<div className='grid gap-6 grid-cols-1 md:grid-cols-4 py-2 text-justify text-romanDarkTextInactive dark:text-romanDarkTextActive'>
				<SummaryCard leadsRdx={leadsRdx} />
				<ShortTrend onGetLeads={getLeadsHandler} leadsRdx={leadsRdx} />
				<LongTrend onGetLeads={getLeadsHandler} leadsRdx={leadsRdx} />
			</div>
		</PageWrapper>
	)
}

export default MarketingContent
