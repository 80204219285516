import React from 'react'
import { ICONS_EMOTICONS } from '../../constants/icons'

const AnnouncementItem = () => {
	return (
		//  <!-- Link 1 -->
		<div className='group relative p-10 text-romanLightTextActive dark:text-romanDarkTextActive rounded-xl  md:col-span-2'>
			{/* <!-- Quotes Image --> */}

			{/* React Button */}
			<div className='hidden absolute -top-3 right-5   group-hover:flex group-hover:flex-row space-x-2 bg-romanLight1 dark:bg-romanDark4 rounded-full  text-gray-500 dark:text-gray-400 text-lg text-center items-center justify-center px-3 py-1'>
				<div className='hover:dark:text-romanLight2 hover:bg-slate-200 dark:bg-romanDark4 rounded-full p-1'>
					{ICONS_EMOTICONS.thumbsUp}
				</div>
				<div className='hover:dark:text-romanLight2 hover:bg-slate-200 dark:bg-romanDark4 rounded-full p-1'>
					{ICONS_EMOTICONS.thumbsDown}
				</div>
				<div className='hover:dark:text-romanLight2 hover:bg-slate-200 dark:bg-romanDark4 rounded-full p-1'>
					{ICONS_EMOTICONS.happy}
				</div>
				<div className='hover:dark:text-romanLight2 hover:bg-slate-200 dark:bg-romanDark4 rounded-full p-1'>
					{ICONS_EMOTICONS.happyBeaming}
				</div>
				<div className='hover:dark:text-romanLight2 hover:bg-slate-200 dark:bg-romanDark4 rounded-full p-1'>
					{ICONS_EMOTICONS.happyHeart}
				</div>
				<div className='hover:dark:text-romanLight2 hover:bg-slate-200 dark:bg-romanDark4 rounded-full p-1'>
					{ICONS_EMOTICONS.sad}
				</div>
				<div className='hover:dark:text-romanLight2 hover:bg-slate-200 dark:bg-romanDark4 rounded-full p-1'>
					{ICONS_EMOTICONS.tear}
				</div>
				<div className='hover:dark:text-romanLight2 hover:bg-slate-200 dark:bg-romanDark4 rounded-full p-1'>
					{ICONS_EMOTICONS.cry}
				</div>
			</div>
			{/* <!-- Box Header --> */}
			<div className='flex z-10 space-x-4'>
				<img
					src='images/image-daniel.jpg'
					alt=''
					className='w-10 h-10 rounded-full ring-2 ring-romanGold2 dark:ring-romanRed2  '
				/>
				<div className='text-sm'>
					<h4 className='opacity-90'>Jamie-Lyn Nardone</h4>
					<p className='opacity-50'>Human Resources</p>
				</div>
			</div>

			{/* <!-- Large Text --> */}
			<p className='relative z-10 mt-6 text-xl'>
				Important Notice: Employee Contact Information
			</p>

			<div className='mt-4 border-b-2 border-b-romanRed2   dark:border-b-romanGold1'></div>

			{/* <!-- Small Text --> */}
			<div className='mt-6 opacity-50 line-clamp-6'>
				<p>Dear Team, </p>
				<br />
				<p>
					I hope this message finds you well. We wanted to take a moment to
					bring clarification to the usage of our company phone list. The phone
					list provides the personal cell phone numbers of our employees;
					therefore, we kindly request everyone to limit its usage to “internal
					use only”. While we understand that providing excellent customer
					service is a top priority, it is crucial that we maintain a respectful
					boundary between our employees' privacy and confidentiality of their
					personal information and our interactions with customers.{' '}
				</p>
				<br />
				<p>
					We appreciate your understanding and cooperation and thank you for
					upholding our company’s values. Let us continue to deliver outstanding
					customer service while respecting the privacy of our team members.{' '}
				</p>
				<br />

				<p>
					If you have any questions, please do not hesitate to reach out to the
					HR department.
				</p>
				<br />
				<p>Sincerely,</p>
				<p> Jamie</p>
			</div>
		</div>

		// <div className='flex flex-col items-center justify-between w-full p-6 bg-white dark:bg-romanDark2  dark:border-1 dark:border-gray-600 rounded-lg md:flex-row'>
		// 	<p className='font-bold text-center text-veryDarkViolet dark:text-white md:text-left'>
		// 		Nick Sizemore
		// 	</p>

		// 	<div className='flex flex-col items-center justify-end flex-1 space-x-4 p-4 space-y-2 '>
		// 		<p className='font-bold text-left text-veryDarkViolet dark:text-white '>
		// 			Please Complete BambooHR Training
		// 		</p>
		// 		<div className='font-normal text-gray-400'>
		// 			Here we can place text regarding an important message or even a review
		// 			that has come in. We can write code to automatically generate an
		// 			announcement based on certain giddy up events.
		// 		</div>
		// 		<button className='p-2 px-8 text-white bg-cyan rounded-lg hover:opacity-70 focus:outline-none'>
		// 			React
		// 		</button>
		// 	</div>
		// </div>
	)
}

export default AnnouncementItem
