//kpi-badges.js
import React, { useEffect, useState } from 'react'
import KPIBadge from './kpi-badge'
import { GEN_CONST } from '../../../constants/general'
import { ICONS } from '../../../constants/icons'
const calcPercent = (e) => Math.floor((e.value / e.target) * 100)
const KpiBadges = ({ totalValue, salesEvents, jobsWithoutPrice }) => {
	const [badgeData, setBadgeData] = useState([])

	useEffect(() => {
		const contractsSignedEvents = salesEvents.filter(
			(event) =>
				event.eventType === 'contractSigned' || event.eventType === 'solarSold',
		)
		const total = contractsSignedEvents.reduce((accumulator, currentEvent) => {
			const priceString = currentEvent.totalContractPrice?.replace(/[$,]/g, '')
			const priceNumber = parseFloat(priceString || 0)

			if (!isNaN(priceNumber)) {
				accumulator += priceNumber // Add to total
			}

			return accumulator
		}, 0)

		const appointmentsRan = salesEvents.filter(
			(event) => event.eventType === 'initialAppointmentCompleted',
		).length

		const templateCounts = {}

		contractsSignedEvents.forEach((event) => {
			const template = event.template
			if (template in templateCounts) {
				templateCounts[template] += 1
			} else {
				templateCounts[template] = 1
			}
		})

		const templateCountArray = Object.keys(templateCounts).map((template) => {
			return { template: template, contractsSigned: templateCounts[template] }
		})

		const concatenatedString = templateCountArray
			.map((e) => `${e.template}: ${e.contractsSigned}`)
			.join(', ')
		const conversionRate =
			contractsSignedEvents.length / Math.max(appointmentsRan, 1) // To avoid division by zero

		const mappedData = [
			{
				kpi: 'Appointments Ran',
				type: GEN_CONST.types.number,
				value: appointmentsRan,
				target: 137, // Assuming a static target, replace with dynamic if needed
				icon: ICONS.data.calendarCheckFill,
			},
			{
				kpi: 'Contracts Signed',
				type: GEN_CONST.types.number,
				value: Math.round((totalValue || total) / 1000),
				valueLabel: { pre: '$', post: 'K' },
				targetLabel: '700K',
				target: 700, // Assuming a static target, replace with dynamic if needed
				icon: ICONS.construction.contractFilled,
				ticker: concatenatedString,
				zeroPrice: jobsWithoutPrice,
			},
			{
				kpi: 'Conversion Rate',
				type: GEN_CONST.types.percent,
				value: conversionRate,
				target: 0.75, // Assuming a static target, replace with dynamic if needed
				icon: ICONS.money.dollarFunnel,
			},
		].map((e) => ({
			...e,
			percentToGoal: calcPercent(e),
		}))

		setBadgeData(mappedData)
	}, [salesEvents, totalValue])

	return (
		<div className=' col-span-1 lg:col-span-4 flex flex-col h-full justify-between'>
			{badgeData.map((e, idx) => (
				<KPIBadge
					key={idx}
					percentToGoal={e.percentToGoal}
					icon={e.icon}
					kpi={e.kpi}
					valueType={e.type}
					valueLabel={e.valueLabel ? e.valueLabel : undefined}
					goalLabel={e.targetLabel ? e.targetLabel : undefined}
					goal={e.target}
					value={e.value}
					tickerString={e.ticker || undefined}
					zeroPrice={e.zeroPrice || undefined}
				/>
			))}
		</div>
	)
}

export default KpiBadges
