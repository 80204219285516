import React, { useEffect } from 'react'
import LongTrend from './components/long-trend'
// import ShortTrend from './components/trend'
import ShortThroughputTrend from './components/throughput-trend'
import SummaryCard from './components/summary-appt-set'
import { useDispatch, useSelector } from 'react-redux'
import { getLeads, getSales } from '../../../redux/actions'
import { CONST_DATES, CONST_ROMAN_KPI } from '../../../constants/general'
import { PulseReportLinks, PageWrapper } from '../../../components'

// Filtering the CONST_ROMAN_KPI to include only those objects where department is 'Sales'
const eventTypes = Object.values(CONST_ROMAN_KPI)
	.filter((kpi) => kpi.department === 'Sales')
	.map((kpi) => kpi.eventType)

const marketingEvents = Object.values(CONST_ROMAN_KPI)
	.filter((kpi) => kpi.department === 'Marketing')
	.map((kpi) => kpi.eventType)

const phaseChanges = ['Contract Signed', 'Appointment Set']

const SalesContent = () => {
	const dispatch = useDispatch()

	const salesRdx = useSelector((state) => state.sales)
	const leadsRdx = useSelector((state) => state.leads)

	useEffect(() => {
		dispatch(
			getSales({
				phaseChanges,
				eventTypes,
				timeRange: CONST_DATES.last7Days,
			}),
		)
		dispatch(
			getSales({
				phaseChanges,
				eventTypes,
				timeRange: CONST_DATES.last30Days,
			}),
		)
		dispatch(
			getSales({
				phaseChanges,
				eventTypes,
				timeRange: CONST_DATES.last90Days,
			}),
		)
		dispatch(
			getLeads({
				eventTypes: marketingEvents,
				timeRange: CONST_DATES.last90Days,
			}),
		)

		return () => {}
	}, [dispatch])

	const getDataHandler = (timeRange) => {
		dispatch(getSales({ eventTypes, phaseChanges, timeRange }))
		dispatch(getLeads({ eventTypes: marketingEvents, timeRange }))
	}

	return (
		<PageWrapper links={<PulseReportLinks />}>
			<div className='grid gap-6 grid-cols-1 md:grid-cols-4 py-2 text-justify text-romanDarkTextInactive dark:text-romanDarkTextActive'>
				<SummaryCard salesRdx={salesRdx} />
				<ShortThroughputTrend
					onGetData={getDataHandler}
					salesRdx={salesRdx}
					leadsRdx={leadsRdx}
				/>

				<LongTrend salesRdx={salesRdx} />
			</div>
		</PageWrapper>
	)
}
export default SalesContent
