//sales-analysis.js
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getJobsInfo, getZapierEvents } from '../../../redux/actions'
import Sidebar from '../../../components/Navigation/sidebar2'
import { AdminLinks, Header2, HomePageLinks } from '../../../components'
import JobList from './job-list'

const SalesAnalysis = () => {
	const dispatch = useDispatch()
	const jobsInfoRdx = useSelector((state) => state.jobsInfo)
	const zapierEventsRdx = useSelector((state) => state.zapierEvents)

	const [jobs, setJobs] = useState([])

	useEffect(() => {
		console.log('first')
		dispatch(getZapierEvents())
		dispatch(
			getJobsInfo({
				jobs: [
					'23-05151',
					'23-05862',
					'23-06670',
					'23-06653',
					'23-05786',
					'23-05617',
					'23-05775',
				],
			}),
		)

		return () => {}
	}, [dispatch])

	useEffect(() => {
		console.log(zapierEventsRdx)
	}, [zapierEventsRdx])

	useEffect(() => {
		console.log(jobsInfoRdx)
		if (jobsInfoRdx && jobsInfoRdx.data) {
			setJobs(jobsInfoRdx.data)
		}

		if (jobsInfoRdx && jobsInfoRdx.error) {
			setJobs(['Error loading jobs...'])
		}

		return () => {}
	}, [jobsInfoRdx.data, jobsInfoRdx.error, jobsInfoRdx])

	return (
		<div className='dark:bg-main-dark-bg bg-main-bg dark:text-romanLight1 font-opensans mt-20'>
			<Sidebar title={'Admin Settings'} links={<AdminLinks />} />
			<Header2 links={<HomePageLinks />} />
			<div className='ml-4 '>
				<JobList jobs={jobs} />
			</div>
		</div>
	)
}

export default SalesAnalysis
