//sales-scorecard-kpi-buttons.js
import React from 'react'

const SalesMenuButtons = ({ activeChartType, onUpdateActiveChartType }) => {
	const updateChartTypeHandler = (type) => {
		onUpdateActiveChartType(type)
	}

	return (
		<div className='flex space-x-4'>
			<button
				className={`px-4 py-2 ${
					activeChartType === 'line'
						? 'bg-romanRedScorecard text-white '
						: 'bg-romanGoldScorecard text-black'
				}  rounded hover:bg-opacity-80`}
				onClick={() => updateChartTypeHandler('line')}
			>
				Line Chart
			</button>
			<button
				className={`px-4 py-2 ${
					activeChartType === 'bar'
						? 'bg-romanRedScorecard text-white '
						: 'bg-romanGoldScorecard text-black'
				}  rounded hover:bg-opacity-80`}
				onClick={() => updateChartTypeHandler('bar')}
			>
				Bar Chart
			</button>
		</div>
	)
}

export default SalesMenuButtons
