//executive-links.js
import React from 'react'
import { ROUTES } from '../../../constants'
import { Link } from 'react-router-dom'

const ExecutiveLinks = () => {
	return (
		<React.Fragment>
			<Link
				to={`${ROUTES.pages.executive.home}`}
				className={
					'mx-4 text-center rounded-theme-rounding bg-romanRed2   dark:bg-romanGold1 py-2 px-4 text-romanLight1 dark:text-romanDark4 hover:opacity-80 hover:-translate-y-0.5'
				}
			>
				Summary
			</Link>
			<Link
				to={`${ROUTES.pages.executive.pnl}`}
				className={
					'mx-4 text-center rounded-theme-rounding bg-romanRed2   dark:bg-romanGold1 py-2 px-4 text-romanLight1 dark:text-romanDark4 hover:opacity-80 hover:-translate-y-0.5'
				}
			>
				Profit & Loss
			</Link>
			<Link
				to={`${ROUTES.pages.executive.bs}`}
				className={
					'mx-4 text-center rounded-theme-rounding bg-romanRed2   dark:bg-romanGold1 py-2 px-4 text-romanLight1 dark:text-romanDark4 hover:opacity-80 hover:-translate-y-0.5'
				}
			>
				Balance Sheet
			</Link>
		</React.Fragment>
	)
}

export default ExecutiveLinks
