//pnl-page.js
import React, { useState } from 'react'
import {
	PageWrapper,
	ScorecardHeader,
	ExecutiveLinks,
	YTDSummary,
	YTDMonthly,
} from '../../../components'
import TopMenu from '../../../components/page-components/executive/pnl/top-menu'

const POSSIBLE_FORMATS = ['NUMBER', 'DOLLAR']
const POSSIBLE_DECIMALS = [0, 1, 2]
const POSSIBLE_FACTORS = [1, 1000, 1000000]

const PnlPage = () => {
	const [dollarBaseFactor, setDollarBaseFactor] = useState(POSSIBLE_FACTORS[1])
	const [dollarDecimal, setDollarDecimal] = useState(0)
	const [dollarFormat, setDollarFormat] = useState('NUMBER')

	const changeFormatHandler = () => {
		const currentIndex = POSSIBLE_FORMATS.indexOf(dollarFormat)
		const nextIndex = (currentIndex + 1) % POSSIBLE_FORMATS.length
		setDollarFormat(POSSIBLE_FORMATS[nextIndex])
	}
	const changeDecimalHandler = () => {
		const currentIndex = POSSIBLE_DECIMALS.indexOf(dollarDecimal)
		const nextIndex = (currentIndex + 1) % POSSIBLE_DECIMALS.length
		setDollarDecimal(POSSIBLE_DECIMALS[nextIndex])
	}

	const changeFactorHandler = () => {
		const currentIndex = POSSIBLE_FACTORS.indexOf(dollarBaseFactor)
		const nextIndex = (currentIndex + 1) % POSSIBLE_FACTORS.length
		setDollarBaseFactor(POSSIBLE_FACTORS[nextIndex])
	}

	return (
		<PageWrapper title='Financials' links={<ExecutiveLinks />}>
			<ScorecardHeader
				title='Income Statement Metrics'
				component={
					<TopMenu
						factor={dollarBaseFactor}
						decimal={dollarDecimal}
						format={dollarFormat}
						onChangeDecimal={changeDecimalHandler}
						onChangeFactor={changeFactorHandler}
						onChangeFormat={changeFormatHandler}
					/>
				}
			/>

			<YTDSummary
				dollarBaseFactor={dollarBaseFactor}
				dollarDecimal={dollarDecimal}
				dollarFormat={dollarFormat}
			/>
			<YTDMonthly
				dollarBaseFactor={dollarBaseFactor}
				dollarDecimal={dollarDecimal}
				dollarFormat={dollarFormat}
			/>
		</PageWrapper>
	)
}

export default PnlPage
