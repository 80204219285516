import axios from 'axios'
import { CONST_API } from '../../../constants/api'
import { REDUX_CONST } from '../../constants/'
import { logout } from '../userActions'
import { CONST_ERRORS } from '../../../constants/general'

export const getZapierEvents = () => async (dispatch, getState) => {
	// console.log('Events:Request:GetEvents')
	try {
		dispatch({
			type: REDUX_CONST.zapier.eventConstants.getZapierEvents.request,
		})

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(
			CONST_API.channels.zapier +
				CONST_API.routes.zapier.events.getZapierEvents,
			config,
		)
		// console.log('Events:Response:GetEvents')
		// console.log(data)

		dispatch({
			type: REDUX_CONST.zapier.eventConstants.getZapierEvents.success,
			payload: data,
		})
	} catch (error) {
		console.log(error)
		const message =
			error.response && error.response.data.message
				? error.response.data.message
				: error.message
		if (message === CONST_ERRORS['401ExpiredToken']) {
			dispatch(logout())
		}
		dispatch({
			type: REDUX_CONST.zapier.eventConstants.getZapierEvents.fail,
			payload: message,
		})
	}
}
