//ytd-summary.js
import React, { useEffect, useState } from 'react'

const DATA = [
	{ metric: 'Revenue', value: 77867034.58 },
	{ metric: 'COGS', value: 61559899.6 },
	{ metric: 'Gross Profit', value: 16307134.98 },
	{ metric: 'EBITDA', value: 5604229.63 },
]

const COMPONENT_STRUCTURE = [
	{
		col1: 'Metric',
		col2: '$',
		col3: '% of Sales',
		col4: 'Target',
		header: true,
	}, //header
	{ col1: 'Revenue' },
	{ col1: 'COGS' },
	{ col1: 'Gross Profit' },
	{ col1: 'EBITDA' },
]

const formatDollar = ({ value, format, factor, decimals }) => {
	// Possible Formats: ['DOLLAR','NUMBER'] // if DOLLAR use $ prefix
	// Possible Factors: [1, 1000, 1000000]  // divide the value by the factor
	// Possible Decimals: [0,1,2]  // use this as the decimals displayed
	// Values = floating point decimal positive and negative

	// Check if value is a number and if factor is not zero to avoid division by zero
	if (typeof value !== 'number' || factor === 0) return ''

	// Divide the value by the factor and fix to the specified decimal places
	let formattedValue = (value / factor).toFixed(decimals)

	// Add commas as thousand separators
	formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

	// Add dollar sign if format is 'DOLLAR'
	if (format === 'DOLLAR') {
		formattedValue = `$${formattedValue}`
	}

	return formattedValue
}

const YTDSummary = ({ dollarBaseFactor, dollarDecimal, dollarFormat }) => {
	const [components, setComponents] = useState(COMPONENT_STRUCTURE)

	// MAP THE DATA INTO THE STRUCTURE WHENEVER OPTIONS OR DATA CHANGES
	useEffect(() => {
		const updatedComponents = COMPONENT_STRUCTURE.map((component) => {
			if (component.header) {
				return component // Return as is for headers
			}

			const dataEntry = DATA.find((d) => d.metric === component.col1)
			const revenueEntry = DATA.find((d) => d.metric === 'Revenue')
			return {
				...component,
				col2: dataEntry
					? formatDollar({
							value: dataEntry.value,
							format: dollarFormat,
							factor: dollarBaseFactor,
							decimals: dollarDecimal,
					  })
					: null,
				col3:
					dataEntry && component.col1 !== 'Revenue'
						? `${((dataEntry.value / revenueEntry.value) * 100).toFixed(
								dollarDecimal,
						  )}%`
						: null,
			}
		})

		setComponents(updatedComponents)
	}, [DATA, dollarBaseFactor, dollarDecimal, dollarFormat])

	return (
		<div className='w-400 my-2'>
			{components.map((component, idx) => (
				<div
					key={idx}
					className={`${
						idx === 0
							? 'font-bold border-b-2 border-romanLightTextActive dark:border-romanDarkTextActive  '
							: ' '
					} grid grid-cols-3 space-x-4`}
				>
					<div className='col-span-1 text-romanLightTextActive dark:text-romanDarkTextActive font-bold '>
						{component.col1}
					</div>
					<div className='col-span-1 text-romanLightTextActive dark:text-romanDarkTextActive'>
						{component.col2}
					</div>
					<div className='col-span-1 text-romanLightTextActive dark:text-romanDarkTextActive'>
						{component.col3}
					</div>
				</div>
			))}
		</div>
	)
}

export default YTDSummary
