//sales-scorecard-new.js
import React, { useEffect, useState } from 'react'
import io from 'socket.io-client'
import {
	PageWrapper,
	ScorecardLinks,
	ScorecardHeader,
	KPIBadges,
	KPICharts,
	TheScore,
	KPIMenu,
	SalesScorecardKPIMenu,
} from '../../../components'
import { CONST_ADD, CONST_DELETE } from '../../../constants/general'
import { useDispatch, useSelector } from 'react-redux'
import { getScorecardData, getJobValues } from '../../../redux/actions/'

const eventTypes = ['initialAppointmentCompleted', 'contractSigned']
const phaseChanges = []

const SalesScorecard = () => {
	const dispatch = useDispatch()
	const scoreCardDataRdx = useSelector((state) => state.scorecardData)
	const jobValuesRdx = useSelector((state) => state.jobValues)
	const [switchInterval, setSwitchInterval] = useState(15)
	const [play, setPlay] = useState(true)
	const [activeChartType, setActiveChartType] = useState('line')
	const [weekOffset, setWeekOffset] = useState(0)
	const [totalValue, setTotalValue] = useState(0)
	const [jobsWithoutPrice, setJobsWithoutPrice] = useState(0)

	useEffect(() => {
		dispatch(
			getScorecardData({
				phaseChanges,
				eventTypes,
				key: 'sales',
				weekOffset,
			}),
		)
	}, [dispatch, weekOffset])

	// CONNECT TO SOCKET FOR UPDATES
	useEffect(() => {
		let intervalId

		if (play) {
			intervalId = setInterval(() => {
				setActiveChartType((prevType) => (prevType === 'line' ? 'bar' : 'line'))
			}, switchInterval * 1000) // Switch chart type based on switchInterval
		}

		return () => {
			if (intervalId) {
				clearInterval(intervalId) // Clear interval on component unmount or if play is set to false
			}
		}
	}, [switchInterval, play]) // Depend on switchInterval and play

	useEffect(() => {
		// io('http://localhost:5000')
		// Connect to the Socket.IO server
		// Replace 'http://localhost:5000' with your server's URL
		const socket = io('https://www.nvoqe.com', { transports: ['websocket'] })
		// Setup event listeners

		socket.on('EVENTS', (data) => {
			if (data.action === 'EVENT_CREATED') {
				const event = data.payload
				if (
					event.eventType === 'initialAppointmentCompleted' ||
					event.eventType === 'contractSigned'
				) {
					if (weekOffset === 0) {
						dispatch(
							getScorecardData({
								phaseChanges,
								eventTypes,
								key: 'sales',
							}),
						)
					}
					// dispatch(
					// 	getLeaderboardData({
					// 		phaseChanges,
					// 		eventTypes,
					// 		key: 'sales',
					// 	}),
					// )
				}
			}
		})
		// Clean up the effect
		return () => socket.disconnect()
	}, [])

	// LISTEN FOR DATA FROM REDUX AND PROCESS

	useEffect(() => {
		if (scoreCardDataRdx.sales) {
			const jobIDs = scoreCardDataRdx.sales
				.filter((item) => item.eventType === 'contractSigned')
				.map((item) => item.jobID)

			dispatch(getJobValues({ jobIDs }))
		}
	}, [scoreCardDataRdx])

	useEffect(() => {
		if (jobValuesRdx?.data?.length > 0) {
			console.log(
				jobValuesRdx.data.filter((job) => job.totalContractPrice === '$0.00'),
			)
			console.log(
				jobValuesRdx.data.filter((job) => job.totalContractPrice !== '$0.00'),
			)

			const data = jobValuesRdx.data.reduce((accumulator, item) => {
				const priceString = item.totalContractPrice?.replace(/[$,]/g, '')
				const priceNumber = parseFloat(priceString || 0)

				if (!isNaN(priceNumber)) {
					accumulator += priceNumber // Add to total
				}

				return accumulator
			}, 0)
			setTotalValue(data)
			setJobsWithoutPrice(
				jobValuesRdx.data.filter((job) => job.totalContractPrice === '$0.00')
					.length,
			)
		}

		return () => {}
	}, [jobValuesRdx])
	const updateActiveChartTypeHandler = (type) => setActiveChartType(type)
	const togglePlayHandler = () => {
		setPlay((prevPlay) => !prevPlay)
	}

	const switchIntervalHandler = (type) => {
		setSwitchInterval((prevInterval) => {
			if (type === CONST_ADD && prevInterval < 60) {
				return prevInterval + 1
			} else if (type !== CONST_ADD && prevInterval > 1) {
				return prevInterval - 1
			}
			return prevInterval // Return the current value if no change is needed
		})
	}
	const weekOffsetHandler = (direction) => {
		setWeekOffset((currentOffset) => {
			if (direction === CONST_ADD && currentOffset < 0) {
				return currentOffset + 1
			} else if (direction === CONST_DELETE && currentOffset > -12) {
				return currentOffset - 1
			}
			return currentOffset
		})
	}
	return (
		<PageWrapper title='Scorecards' links={<ScorecardLinks />}>
			<ScorecardHeader
				title='SALES TEAM SCOREBOARD'
				component={<TheScore total={totalValue} target={700000} />}
			/>
			<KPIMenu
				weekOffset={weekOffset}
				onSetWeekOffset={weekOffsetHandler}
				play={play}
				onSwitchIntervalHandler={switchIntervalHandler}
				onTogglePlayHandler={togglePlayHandler}
				switchInterval={switchInterval}
				buttons={
					<SalesScorecardKPIMenu
						activeChartType={activeChartType}
						onUpdateActiveChartType={updateActiveChartTypeHandler}
					/>
				}
			/>
			<div className='grid grid-cols-1 lg:grid-cols-10 gap-6  py-2 px-6 lg:px-0 text-justify text-romanDarkTextInactive dark:text-romanDarkTextActive'>
				<KPIBadges
					jobsWithoutPrice={jobsWithoutPrice}
					totalValue={totalValue > 0 ? totalValue : undefined}
					salesEvents={
						scoreCardDataRdx?.sales?.length > 0 ? scoreCardDataRdx.sales : []
					}
				/>
				<KPICharts
					activeChartType={activeChartType}
					salesEvents={
						scoreCardDataRdx?.sales?.length > 0 ? scoreCardDataRdx.sales : []
					}
				/>
			</div>
		</PageWrapper>
	)
}

export default SalesScorecard
