//bs-page.js
import React from 'react'
import {
	PageWrapper,
	ScorecardHeader,
	ExecutiveLinks,
} from '../../../components'

const BSPage = () => {
	return (
		<PageWrapper title='Financials' links={<ExecutiveLinks />}>
			<ScorecardHeader title='Balance Sheet Metrics' />
		</PageWrapper>
	)
}

export default BSPage
