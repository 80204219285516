//appointment-set.js
import React, { useEffect, useState } from 'react'

const AppointmentSet = ({ appointmentsSet }) => {
	const [selected, setSelected] = useState([])
	const [mappedAppointments, setMappedAppointments] = useState([])

	const handleSelection = (jobID) => {
		setSelected((prevSelected) => {
			if (prevSelected.includes(jobID)) {
				// If jobID is already selected, remove it from the array
				return prevSelected.filter((id) => id !== jobID)
			} else {
				// If jobID is not selected, add it to the array
				return [...prevSelected, jobID]
			}
		})
	}

	useEffect(() => {
		const newMappedAppointments = appointmentsSet.map((appt) => ({
			...appt,
			selected: selected.includes(appt.jobID), // Add the selected property based on presence in selected array
		}))
		setMappedAppointments(newMappedAppointments)
	}, [selected, appointmentsSet]) // Dependency array includes both selected and appointmentsSet

	return (
		<React.Fragment>
			<div className='py-3 font-bold text-3xl lg:text-justify md:text-4xl text-romanLightTextActive dark:text-romanDarkTextActive'>
				Appointments Set
			</div>
			<div className='grid grid-cols-1 gap-1 text-xs justify-center items-center text-center text-romanGray800Scorecard  '>
				<div className='grid grid-cols-9 p-4 w-full bg-romanGray800Scorecard text-white rounded-lg text-xs  font-bold justify-items-center border-b-2 border-romanGray800Scorecard '>
					<div className=''>Date</div>
					<div className=''>Job ID</div>
					<div className=''>Template</div>
					<div className=''>Phase</div>
					<div className=''>Lead Source</div>
					<div className=''>Lead Type</div>
					<div className=''>Event Contact</div>
					<div className=''>Event Type</div>
					<div className=''>Sales Rep</div>
				</div>
				<div className='overflow-scroll max-h-[600px]'>
					{mappedAppointments.map((lead, idx) => (
						<div
							key={idx}
							className={`${
								lead.selected ? 'bg-green-500' : 'bg-romanGray200Scorecard'
							} grid grid-cols-9 p-2 w-full justify-items-center border-1 border-romanGray800Scorecard rounded-lg`}
							onClick={() => handleSelection(lead.jobID)}
						>
							<div>{lead.date}</div>
							<div>{lead.jobID}</div>
							<div>{lead.template}</div>
							<div>{lead.phase}</div>
							<div>{lead.leadSource}</div>
							<div>{lead.leadType}</div>
							<div>{lead.eventContact}</div>
							<div>{lead.eventType}</div>
							<div>{lead.salesRep}</div>
						</div>
					))}
				</div>
			</div>
		</React.Fragment>
	)
}

export default AppointmentSet
