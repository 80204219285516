export const EVENTS_LIST_ALL_REQUEST = 'EVENTS_LIST_ALL_REQUEST'
export const EVENTS_LIST_ALL_SUCCESS = 'EVENTS_LIST_ALL_SUCCESS'
export const EVENTS_LIST_ALL_FAIL = 'EVENTS_LIST_ALL_FAIL'

export const eventConstants = {
	getZapierEvents: {
		request: EVENTS_LIST_ALL_REQUEST,
		success: EVENTS_LIST_ALL_SUCCESS,
		fail: EVENTS_LIST_ALL_FAIL,
	},
}
