import { REDUX_CONST } from '../../constants'

export const getZapierEvents = (state = { data: [] }, action) => {
	switch (action.type) {
		case REDUX_CONST.zapier.eventConstants.getZapierEvents.request:
			return { loading: true }
		case REDUX_CONST.zapier.eventConstants.getZapierEvents.success:
			return { data: action.payload }
		case REDUX_CONST.zapier.eventConstants.getZapierEvents.fail:
			return { error: action.payload }
		default:
			return state
	}
}
