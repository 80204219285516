//sales-rep-chart.js
import React, { useEffect, useState } from 'react'

import { CONST_COLORS, CONST_ROMAN_KPI } from '../../../../constants/general'

// import ShortChartTrend from './throughput-trend-chart'
import SalesRepBarChart from './sales-rep-chart'

// with salesData
// event.template =-> potential values:
// Inbound Digital Lead Triage
// Inbound Lead Triage
// Insurance Work
// Repairs
// Residential Retail

// Map event.template === 'Inbound Digital Lead Triage' OR event.template=== 'Inbound Lead Triage' into event.template= 'Inbound Lead'
// Map event.template === 'Residential Retail' into event.template = 'Reroof'
const barChartColors = [
	{
		dataKey: CONST_ROMAN_KPI.estimateSent.chartName,
		color: CONST_COLORS.romanRed2,
	},

	{
		dataKey: CONST_ROMAN_KPI.contractSigned.chartName,
		color: CONST_COLORS.romanDarkTextInactive,
	},
	{
		dataKey: CONST_ROMAN_KPI.salesThroughput.appointmentsRan.chartName,
		color: CONST_COLORS.romanRed5,
	},
]

const SalesRepChart = ({ salesRdx, timeRange }) => {
	const [chartData, setChartData] = useState([])

	useEffect(() => {
		// Dynamically determine the data key based on the selected time range
		const salesDataKey = `sales_${timeRange.range.replace('last', 'last')}`
		const salesData = salesRdx[salesDataKey] || []

		// Function to map the data based on the specified criteria
		const mapData = (data) => {
			// console.log(data)
			return data.map((event) => {
				// Mapping for salesData
				if (event.eventType === 'initialAppointmentCompleted') {
					event.eventType = 'appointmentsRan'
				}

				if (event.eventType === 'solarSold') {
					event.eventType = 'contractSigned'
				}

				return event
			})
		}

		const chartArray = mapData(salesData)
		setChartData(chartArray)

		return () => {}
	}, [salesRdx])

	return (
		<SalesRepBarChart
			barChartColors={barChartColors}
			rawData={chartData}
			timeRange={timeRange}
		/>
	)
}

export default SalesRepChart
