// departments.js
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDepartments } from '../../../redux/actions'
import DepartmentList from './department-list'
import Sidebar from '../../../components/Navigation/sidebar2'
import { AdminLinks, Header2, HomePageLinks } from '../../../components'

const Departments = () => {
	const dispatch = useDispatch()
	const departmentsRdx = useSelector((state) => state.departments)

	const [departments, setDepartments] = useState([])

	useEffect(() => {
		dispatch(getDepartments())

		return () => {}
	}, [dispatch])

	useEffect(() => {
		if (departmentsRdx && departmentsRdx.data) {
			setDepartments(departmentsRdx.data)
		}

		if (departmentsRdx && departmentsRdx.error) {
			setDepartments(['Error loading departments...'])
		}

		return () => {}
	}, [departmentsRdx.data, departmentsRdx.error, departmentsRdx])

	return (
		<div className='dark:bg-main-dark-bg bg-main-bg dark:text-romanLight1 font-opensans mt-20'>
			<Sidebar title={'Admin Settings'} links={<AdminLinks />} />
			<Header2 links={<HomePageLinks />} />
			<div className='ml-4 '>
				<DepartmentList departments={departments} />
			</div>
		</div>
	)
}

export default Departments
