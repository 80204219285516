//sidebar2.js
import React, { useState } from 'react'
import { ICONS_DEFAULT } from '../../constants/icons'

const Sidebar = ({ links, title }) => {
	const [isSidebarOpen, setIsSidebarOpen] = useState(false)

	const toggleSidebarHandler = () => setIsSidebarOpen(!isSidebarOpen)
	const sidebarClass = isSidebarOpen ? ' translate-x-0 ' : ' -translate-x-full '
	const sideBorderClass = isSidebarOpen
		? 'left-72 w-2 bg-romanLight2 dark:bg-romanDark3 border-romanLight4 dark:border-romanDarkBorder1 '
		: 'left-0  w-3 bg-romanRed2   dark:bg-romanGold1 border-romanLight4 dark:border-romanDark4'
	const sideButtonClass = isSidebarOpen
		? ' left-[272px] bg-romanLight2 dark:bg-romanDark3 text-romanLight4 dark:text-romanDarkBorder1 '
		: ' -left-2   bg-romanRed2   dark:bg-romanGold1 text-romanLight1 dark:text-romanDark4   '

	return (
		<div className='flex   '>
			<div
				className={`fixed pt-40 top-0 left-0 h-full w-72 bg-romanLight3 dark:bg-romanDark3 transition-all transform ${sidebarClass}  z-50 bg-opacity-50 dark:bg-opacity-50 `}
			>
				<h2 className='mt-5 text-center font-bold text-xl mb-4'>{title}</h2>
				<div className='flex flex-col space-y-3 justify-center'>{links}</div>
			</div>
			<div className='group '>
				<div
					onClick={toggleSidebarHandler}
					className={`${sideBorderClass}  fixed top-0 h-full  border-r-1  dark:group-hover:border-romanDarkTextActive hover:cursor-pointer group-hover:border-romanLightTextInactive group-hover:-translate-y-0.5  duration-150 transition-all z-50 `}
				></div>
				<div
					onClick={toggleSidebarHandler}
					className={`${sideButtonClass}  fixed top-32  flex items-center justify-center   h-8 w-8  border-1 border-romanLight4 dark:border-romanDarkBorder1 dark:group-hover:border-romanDarkTextActive dark:group-hover:text-romanDarkTextActive group-hover:border-romanLightTextInactive group-hover:text-romanLightTextInactive rounded-full  hover:cursor-pointer group-hover:-translate-y-0.5  duration-150 transition-all z-50`}
				>
					{isSidebarOpen
						? ICONS_DEFAULT.chevronLeft
						: ICONS_DEFAULT.chevronRight}
				</div>
			</div>
		</div>
	)
}
export default Sidebar
