//tooltip.js
import React, { useState } from 'react'

const Tooltip = ({ children, message, className, enabled }) => {
	const [isHovered, setIsHovered] = useState(false)

	return (
		<div
			className={`relative flex ${
				className ? className : 'items-center justify-center'
			}`}
			onMouseEnter={enabled ? () => setIsHovered(true) : undefined}
			onMouseLeave={enabled ? () => setIsHovered(false) : undefined}
		>
			{children}
			{isHovered && (
				<div className='absolute bottom-full mb-2 px-3 py-1 bg-black text-white text-sm rounded'>
					{message}
				</div>
			)}
		</div>
	)
}

export default Tooltip
