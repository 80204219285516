//marketing-content.js
import React, { useEffect, useState } from 'react'

import SummaryCard from './components/top-left-new'
import ShortTrend from './components/top-right-new'
import LongTrend from './components/bottom-new'
import { useDispatch, useSelector } from 'react-redux'
import { CONST_DATES, CONST_ROMAN_KPI } from '../../../constants/general'
import { getLeads } from '../../../redux/actions'
import {
	PulseReportLinks,
	PageWrapper,
	ScorecardHeader,
	Tooltip,
} from '../../../components'

import Toggle from 'react-toggle'
import 'react-toggle/style.css' // for ES6 modules

// Filtering the CONST_ROMAN_KPI to include only those objects where department is 'Marketing'
const eventTypes = Object.values(CONST_ROMAN_KPI)
	.filter((kpi) => kpi.department?.toLowerCase() === 'marketing')
	.map((kpi) => kpi.eventType)

// Modify ToggleISTFilter to accept props
const ToggleISTFilter = ({ istFilter, setISTFilter }) => {
	const handleChange = () => {
		setISTFilter((prevFilter) => !prevFilter)
	}

	return (
		<Tooltip
			enabled={true}
			message='Turn on to show only IST performance.'
			className={`w-1/2 items-center justify-end text-inherit font-normal`}
		>
			<div className='flex items-center space-x-2 font-bold text-sm'>
				<div>{istFilter ? 'IST Only' : 'All Data'}</div>
				<Toggle checked={istFilter} onChange={handleChange} icons={false} />
			</div>
		</Tooltip>
	)
}

const MarketingContent = () => {
	const dispatch = useDispatch()
	const leadsRdx = useSelector((state) => state.leads)

	const [istFilter, setISTFilter] = useState(true)

	useEffect(() => {
		dispatch(
			getLeads({
				eventTypes,
				timeRange: CONST_DATES.last7Days,
			}),
		)
		dispatch(
			getLeads({
				eventTypes,
				timeRange: CONST_DATES.last30Days,
			}),
		)
		dispatch(
			getLeads({
				eventTypes,
				timeRange: CONST_DATES.last90Days,
			}),
		)

		return () => {}
	}, [dispatch])

	const getLeadsHandler = (timeRange) => {
		console.log(timeRange)
		dispatch(getLeads({ eventTypes, timeRange }))
	}

	return (
		<PageWrapper title={'Pulse Report'} links={<PulseReportLinks />}>
			<ScorecardHeader
				title={'MARKETING PULSE REPORT'}
				component={
					<ToggleISTFilter istFilter={istFilter} setISTFilter={setISTFilter} />
				}
			/>
			<div className='grid gap-6 grid-cols-1 md:grid-cols-4 py-2 text-justify text-romanDarkTextInactive dark:text-romanDarkTextActive'>
				<SummaryCard leadsRdx={leadsRdx} />
				<ShortTrend onGetLeads={getLeadsHandler} leadsRdx={leadsRdx} />
				<LongTrend onGetLeads={getLeadsHandler} leadsRdx={leadsRdx} />
			</div>
		</PageWrapper>
	)
}

export default MarketingContent
