import axios from 'axios'
import { CONST_API } from '../../../constants/api'
import { hrConstants } from '../../constants/hrConstants'

import { logout } from '../userActions'
import { CONST_ERRORS } from '../../../constants/general'

export const resetPayGradeItem = (key) => async (dispatch) => {
	switch (key) {
		case 'payGradeCreate':
			dispatch({ type: hrConstants.payGradeConstants.payGradeCreate.reset })

			break
		case 'payGradeUpdate':
			dispatch({ type: hrConstants.payGradeConstants.payGradeUpdate.reset })

			break
		case 'payGradeDelete':
			dispatch({ type: hrConstants.payGradeConstants.payGradeDelete.reset })

			break
		default:
			break
	}
}

export const payGradeList = () => async (dispatch, getState) => {
	// console.log('payGradeList')
	try {
		dispatch({ type: hrConstants.payGradeConstants.payGradeList.request })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		const { data } = await axios.get(
			CONST_API.channels.hr + CONST_API.routes.hr.payGrades,
			config,
		)

		dispatch({
			type: hrConstants.payGradeConstants.payGradeList.success,
			payload: data,
		})
	} catch (error) {
		const message =
			error.response && error.response.data.message
				? error.response.data.message
				: error.message
		if (message === CONST_ERRORS['401ExpiredToken']) {
			dispatch(logout())
		}
		dispatch({
			type: hrConstants.payGradeConstants.payGradeList.fail,
			payload: message,
		})
	}
}

export const payGradeCreate =
	({
		name,
		level,
		grade,
		targetSalary,
		min,
		max,
		// previousPayGrade,
		position,
	}) =>
	async (dispatch, getState) => {
		try {
			dispatch({ type: hrConstants.payGradeConstants.payGradeCreate.request })

			const {
				userLogin: { userInfo },
			} = getState()

			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
				},
			}

			// // COMPLETE_ME CHECK FOR DUPLICATION ???
			const { data } = await axios.post(
				CONST_API.channels.hr + CONST_API.routes.hr.payGrades,
				{
					name,
					level,
					grade,
					targetSalary,
					min,
					max,
					// previousPayGrade,
					position,
				},
				config,
			)

			dispatch({
				type: hrConstants.payGradeConstants.payGradeCreate.success,
				payload: data,
			})
		} catch (error) {
			const message =
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			if (message === CONST_ERRORS['401ExpiredToken']) {
				dispatch(logout())
			}
			dispatch({
				type: hrConstants.payGradeConstants.payGradeCreate.fail,
				payload: message,
			})
		}
	}

export const payGradeUpdate = (payGrade) => async (dispatch, getState) => {
	try {
		// console.log(payGrade)

		dispatch({ type: hrConstants.payGradeConstants.payGradeUpdate.request })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}
		// console.log(payGrade)
		const { data } = await axios.put(
			CONST_API.channels.hr +
				CONST_API.routes.hr.payGrades +
				`/${payGrade._id}`,
			payGrade,
			config,
		)
		// console.log(data)
		dispatch({
			type: hrConstants.payGradeConstants.payGradeUpdate.success,
			payload: data,
		})
		dispatch({
			type: hrConstants.payGradeConstants.payGradeDetails.success,
			payload: data,
		})
	} catch (error) {
		const message =
			error.response && error.response.data.message
				? error.response.data.message
				: error.message
		if (message === CONST_ERRORS['401ExpiredToken']) {
			dispatch(logout())
		}
		dispatch({
			type: hrConstants.payGradeConstants.payGradeUpdate.fail,
			payload: message,
		})
	}
}

export const payGradeDelete = (payGrade) => async (dispatch, getState) => {
	try {
		dispatch({ type: hrConstants.payGradeConstants.payGradeDelete.request })

		const {
			userLogin: { userInfo },
		} = getState()

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
			},
		}

		await axios.delete(
			CONST_API.channels.hr +
				CONST_API.routes.hr.payGrades +
				`/${payGrade._id}`,
			config,
		)
		dispatch({ type: hrConstants.payGradeConstants.payGradeDelete.success })
	} catch (error) {
		const message =
			error.response && error.response.data.message
				? error.response.data.message
				: error.message
		if (message === CONST_ERRORS['401ExpiredToken']) {
			dispatch(logout())
		}
		dispatch({
			type: hrConstants.payGradeConstants.payGradeDelete.fail,
			payload: message,
		})
	}
}
