//scorecard-links.js
import React from 'react'
import { ROUTES } from '../../../constants'
import { Link } from 'react-router-dom'

const ScorecardLinks = () => {
	return (
		<React.Fragment>
			<Link
				to={`${ROUTES.pages.departmentScorecards.marketing}`}
				className={
					'mx-4 text-center rounded-theme-rounding bg-romanRed2   dark:bg-romanGold1 py-2 px-4 text-romanLight1 dark:text-romanDark4 hover:opacity-80 hover:-translate-y-0.5'
				}
			>
				Marketing
			</Link>
			<Link
				to={`${ROUTES.pages.departmentScorecards.sales}`}
				className={
					'mx-4 text-center rounded-theme-rounding bg-romanRed2   dark:bg-romanGold1 py-2 px-4 text-romanLight1 dark:text-romanDark4 hover:opacity-80 hover:-translate-y-0.5'
				}
			>
				Sales
			</Link>
			{/* <Link
				to={`${ROUTES.pages.departmentScorecards.admin}`}
				className={
					'mx-4 text-center rounded-theme-rounding bg-romanRed2   dark:bg-romanGold1 py-2 px-4 text-romanLight1 dark:text-romanDark4 hover:opacity-80 hover:-translate-y-0.5'
				}
			>
				Admin?
			</Link>
			<Link
				to={`${ROUTES.pages.departmentScorecards.admin}`}
				className={
					'mx-4 text-center rounded-theme-rounding bg-romanRed2   dark:bg-romanGold1 py-2 px-4 text-romanLight1 dark:text-romanDark4 hover:opacity-80 hover:-translate-y-0.5'
				}
			>
				Inside Sales?
			</Link>
			<Link
				to={`${ROUTES.pages.departmentScorecards.admin}`}
				className={
					'mx-4 text-center rounded-theme-rounding bg-romanRed2   dark:bg-romanGold1 py-2 px-4 text-romanLight1 dark:text-romanDark4 hover:opacity-80 hover:-translate-y-0.5'
				}
			>
				Permitting?
			</Link>
			<Link
				to={`${ROUTES.pages.departmentScorecards.admin}`}
				className={
					'mx-4 text-center rounded-theme-rounding bg-romanRed2   dark:bg-romanGold1 py-2 px-4 text-romanLight1 dark:text-romanDark4 hover:opacity-80 hover:-translate-y-0.5'
				}
			>
				Pre-Production?
			</Link> */}
			<Link
				to={`${ROUTES.pages.departmentScorecards.production}`}
				className={
					'mx-4 text-center rounded-theme-rounding bg-romanRed2   dark:bg-romanGold1 py-2 px-4 text-romanLight1 dark:text-romanDark4 hover:opacity-80 hover:-translate-y-0.5'
				}
			>
				Production
			</Link>
			<Link
				to={`${ROUTES.pages.departmentScorecards.collections}`}
				className={
					'mx-4 text-center rounded-theme-rounding bg-romanRed2   dark:bg-romanGold1 py-2 px-4 text-romanLight1 dark:text-romanDark4 hover:opacity-80 hover:-translate-y-0.5'
				}
			>
				Collections
			</Link>
		</React.Fragment>
	)
}

export default ScorecardLinks
