//top-menu.js
import React from 'react'

const TopMenu = ({
	onChangeFormat,
	onChangeDecimal,
	onChangeFactor,
	factor,
	decimal,
	format,
}) => {
	return (
		<div className='flex flex-col md:flex-row justify-center items-center  mt-4 md:mt-0 mb-4 md:mb-0 text-lg space-y-4 space-x-0 md:space-y-0 md:space-x-4'>
			<div className='flex space-x-4'>
				<button
					className={`px-4 py-2 ${
						format === 'DOLLAR'
							? 'bg-romanRedScorecard text-white '
							: 'bg-romanGoldScorecard text-black'
					}  rounded hover:bg-opacity-80`}
					onClick={() => onChangeFormat()}
				>
					{format === 'DOLLAR' ? '$' : '#'}
				</button>
			</div>
			<div className='flex space-x-4'>
				<button
					className={`px-4 py-2 ${'bg-romanRedScorecard text-white '}  rounded hover:bg-opacity-80`}
					onClick={() => onChangeFactor()}
				>
					{factor === 1 ? '1' : factor === 1000 ? '1K' : '1MM'}
				</button>
			</div>
			<div className='flex space-x-4'>
				<button
					className={`px-4 py-2 ${'bg-romanRedScorecard text-white '}  rounded hover:bg-opacity-80`}
					onClick={() => onChangeDecimal()}
				>
					{decimal === 0 ? '.' : decimal === 1 ? '.0' : '.00'}
				</button>
			</div>
		</div>
	)
}

export default TopMenu
