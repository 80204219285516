const { cartConstants } = require('./cartConstants')
const { kpiConstants } = require('./kpiConstants')
const { orderConstants } = require('./orderConstants')
const { productConstants } = require('./productConstants')
const { projectConstants } = require('./projectConstants')
const { userConstants } = require('./userConstants/userConstants')
const {
	departmentConstants,
} = require('./departmentConstants/departmentConstants')
const { settingsConstants } = require('./settingsConstants')
const { giddyUp } = require('./giddyUpConstants/')
const { zapier } = require('./zapierConstants/')

exports.REDUX_CONST = {
	zapier,
	cartConstants,
	kpiConstants,
	orderConstants,
	productConstants,
	projectConstants,
	userConstants,
	settingsConstants,
	giddyUp,
	departmentConstants,
}
