//long-trend.js
import React, { useState } from 'react'
import GridWrapper from '../../grid-wrapper'
import SalesRepChart from './sales-rep'
import { CONST_DATES } from '../../../../constants/general'

const MenuContent = ({ onChangeRange, timeRange }) => {
	return (
		<div className='text-xs flex justify-evenly space-x-2 items-center'>
			<div
				onClick={() => onChangeRange(CONST_DATES.last7Days)}
				className={`px-2 py-1 border-1 ${
					timeRange.range === CONST_DATES.last7Days
						? 'border-romanRed2  '
						: 'border-romanLightTextInactive'
				}  ${
					timeRange.range === CONST_DATES.last7Days
						? 'dark:border-romanGold1'
						: 'dark:border-romanDarkBorder1'
				}  rounded-full `}
			>
				7 days
			</div>
			<div
				onClick={() => onChangeRange(CONST_DATES.last30Days)}
				className={`px-2 py-1 border-1 ${
					timeRange.range === CONST_DATES.last30Days
						? 'border-romanRed2  '
						: 'border-romanLightTextInactive'
				}  ${
					timeRange.range === CONST_DATES.last30Days
						? 'dark:border-romanGold1'
						: 'dark:border-romanDarkBorder1'
				}  rounded-full `}
			>
				30 days
			</div>
			<div
				onClick={() => onChangeRange(CONST_DATES.last90Days)}
				className={`px-2 py-1 border-1 ${
					timeRange.range === CONST_DATES.last90Days
						? 'border-romanRed2  '
						: 'border-romanLightTextInactive'
				}  ${
					timeRange.range === CONST_DATES.last90Days
						? 'dark:border-romanGold1'
						: 'dark:border-romanDarkBorder1'
				}  rounded-full `}
			>
				90 days
			</div>
			<div
				onClick={() => onChangeRange(CONST_DATES.last180Days)}
				className={`px-2 py-1 border-1 ${
					timeRange.range === CONST_DATES.last180Days
						? 'border-romanRed2  '
						: 'border-romanLightTextInactive'
				}  ${
					timeRange.range === CONST_DATES.last180Days
						? 'dark:border-romanGold1'
						: 'dark:border-romanDarkBorder1'
				}  rounded-full `}
			>
				180 days
			</div>
		</div>
	)
}

const LongTrend = ({ salesRdx }) => {
	const [timeRange, setTimeRange] = useState({
		range: CONST_DATES.last90Days,
		title: 'Previous 90 Days',
	})

	const changeRangeHandler = (key) => {
		switch (key) {
			case CONST_DATES.last90Days:
				setTimeRange({
					range: CONST_DATES.last90Days,
					title: 'Previous 90 Days',
				})
				break
			case CONST_DATES.last30Days:
				setTimeRange({
					range: CONST_DATES.last30Days,
					title: 'Previous 30 Days',
				})
				break
			case CONST_DATES.last180Days:
				setTimeRange({
					range: CONST_DATES.last180Days,
					title: 'Previous 180 Days',
				})
				break
			case CONST_DATES.last7Days:
				setTimeRange({ range: CONST_DATES.last7Days, title: 'Previous 7 Days' })
				break

			default:
				break
		}
	}

	return (
		<GridWrapper
			firstLine={'Table'}
			secondLine={'Title'}
			colSpan={' md:col-span-4 '}
			rightContent={
				<MenuContent timeRange={timeRange} onChangeRange={changeRangeHandler} />
			}
		>
			<SalesRepChart salesRdx={salesRdx} timeRange={timeRange} />
		</GridWrapper>
	)
}

export default LongTrend
