import React, { useEffect } from 'react'
import Loader from './Loader'
import { useDispatch, useSelector } from 'react-redux'
import { useStateContext } from '../../contexts/ContextProvider'
import {
	levelList,
	resetLevelItem,
	resetPayGradeItem,
	payGradeList,
} from '../../redux/actions/hrActions'
import {
	getUsers,
	resetUserItem,
} from '../../redux/actions/userActions/userActions'
import {
	getPhases,
	resetPhaseItem,
} from '../../redux/actions/giddyUpActions/phaseActions'

const DeleteWarning = ({
	message,
	onClick,
	warningClassname,
	buttonClassname,
}) => {
	const dispatch = useDispatch()
	const levelDeleteRdx = useSelector((state) => state.levelDelete)
	const { loading: delete1Loading, success: delete1Success } = levelDeleteRdx
	const deleteUserRdx = useSelector((state) => state.deleteUser)
	const { loading: delete3Loading, data: delete3Success } = deleteUserRdx
	const deletePhaseRdx = useSelector((state) => state.deletePhase)
	const { loading: delete4Loading, data: delete4Success } = deletePhaseRdx
	const payGradeDeleteRdx = useSelector((state) => state.payGradeDelete)
	const { loading: delete2Loading, success: delete2Success } = payGradeDeleteRdx

	const { handleClick, initialState } = useStateContext()

	useEffect(() => {
		if (delete1Success) {
			handleClick({ ...initialState })
			delete1Success && dispatch(resetLevelItem('levelDelete'))
			dispatch(levelList())
		} else if (delete2Success) {
			handleClick({ ...initialState })
			delete2Success && dispatch(resetPayGradeItem('payGradeDelete'))
			setTimeout(() => {
				dispatch(payGradeList())
			}, 0)
		} else if (delete3Success) {
			handleClick({ ...initialState })
			delete3Success && dispatch(resetUserItem('deleteUser'))
			setTimeout(() => {
				dispatch(getUsers())
			}, 0)
		} else if (delete4Success) {
			handleClick({ ...initialState })
			delete4Success && dispatch(resetPhaseItem('deletePhase'))
			setTimeout(() => {
				dispatch(getPhases())
			}, 0)
		}

		return () => {}
	}, [delete1Success, delete2Success, delete3Success, delete4Success])
	return (
		<>
			<div
				className={
					warningClassname
						? warningClassname
						: 'mt-2 text-romanRed2   font-bold dark:text-romanGold1 p-4 text-md w-full mx-auto'
				}
			>
				{message}
			</div>
			<div className='flex justify-center'>
				<button
					onClick={onClick}
					className={
						buttonClassname
							? buttonClassname
							: ' mt-2 text-romanRed2   dark:text-romanGold1 border-1  border-romanRed2   dark:border-romanGold1 rounded-theme-rounding font-bold text-md w-2/4 py-1 text-center mx-auto'
					}
				>
					{delete1Loading ||
					delete2Loading ||
					delete3Loading ||
					delete4Loading ? (
						<Loader />
					) : (
						'Delete'
					)}
				</button>
			</div>
		</>
	)
}

export default DeleteWarning
